import React, { useEffect, useState } from "react";
import "./Certificate.css";
import NoCertificate from "../../images/certificate2-svg.webp";
import AppealImg from "../../images/appeial.png";
import "leaflet/dist/leaflet.css";
import CertificateImg from "../../images/certificate-img.png";
import html2pdf from "html2pdf.js";
import ScrollToTop from "../../Components/ScrollTop/ScrollTop";
import PhoneInput from "react-phone-input-2";

const center = [41.2932836, 69.2215172];
const mapUrl = "https://yandex.ru/map-widget/v1/?lang=en_FR&amp;scroll=true&amp;source=constructor-api&amp;um=constructor%3A507c2e5b16c7ce1bc60b1c81a7573491100d9c8f45f1f152c29b8271682baf74";

const Sertificate = () => {
  const [fillingApp, setFillingApp] = useState(false);
  const [phone, setPhone] = useState("");

  const fillingHandle = () => {
    setFillingApp((x) => !x);
  };

  const downloadPDF = () => {
    const imgElement = document.getElementById("certificate-img");
    const options = {
      margin: 0,
      filename: "certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(imgElement).save();
  };

  const certificate = false;

  return (
    <>
      {!certificate & !fillingApp ? (
        <div className="container">
          <div className="lesson-block">
            <div className="lesson-name certificate-namee">
              <h2 className="course-name">My certificates</h2>
            </div>
            <div className="complaint">
              <button className="complaint-btn">
                <span className="attention">!</span>
                Complaint
              </button>
            </div>
          </div>
          <div className="certificate-block">
            <img
              className="noCertificate"
              src={NoCertificate}
              alt="certificate img"
            />
            <p className="certificate-desc">
              You do not have any certificates yet, please apply to get them.
            </p>
            <button onClick={fillingHandle} className="certificate-btn">
              Leave a message
            </button>
          </div>
        </div>
      ) : (
        !certificate && (
          <>
            <div className="complaint certificate-request-complain">
              <button className="complaint-btn">
                <span className="attention">!</span>
                Complaint
              </button>
            </div>
            <div className="container appeal">
              <ScrollToTop />

              <div className="appeal-flex">
                <form>
                  <h2 className="appeal-title title-black">
                    Leave an application for a certificate
                  </h2>
                  <div className="appeal-addres-block">
                    <div className="input-block">
                      <input
                        required
                        placeholder="Name"
                        type="text"
                        className="appeal-input"
                      />
                      <input
                        required
                        placeholder="Email"
                        type="email"
                        className="appeal-input"
                      />
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        placeholder="8023456789"
                        h
                        onChange={(phone) => setPhone(phone)}
                        inputStyle={{
                          height: "52px",
                          paddingLeft: "70px",
                          width: "100%",
                          background: "#EFF1F999",
                          opacity: "60%",
                          border: "none",
                        }}
                        buttonStyle={{
                          border: "none",
                          borderRadius: "8px 0 0 8px",
                          padding: "8px 16px",
                          height: "52px",
                        }}
                        dropdownStyle={{
                          borderRadius: "8px",
                          padding: 0,
                        }}
                      />
                    </div>
                  </div>
                  <div className="appeal-submit">
                    <button onClick={fillingHandle} className="appeal-cancel">
                      Cancel
                    </button>
                    <button type="submit" className="appeal-confirm">
                      Confirm
                    </button>
                  </div>
                </form>
                <div className="appeal-text">
                  <h2 className="appeal-title appeal-title-text">
                    Apply for a certificate
                  </h2>
                  <div className="appeal-text-left">
                    <p className="appeal-doc">
                      It is written here that you should come to the office to
                      receive the certificate and bring the necessary documents
                      with you.
                    </p>
                    <p className="appeal-doc">
                      There should be a text here reminding you that you can get a
                      certificate at the end of the course within 1 year, and that
                      you can't get it after 1 year.
                    </p>
                  </div>
                  <img className="appeal-img" src={AppealImg} alt="Appeal img" />
                </div>
              </div>
              <div className="appeal-addres">
                <p className="addres-title">Exam center address:</p>
                <div>
                  <a
                    href="https://www.google.com/maps/place/Muqimiy+ko'chasi+55,+%D0%A2%D0%BEshkent,+Toshkent,+O%CA%BBzbekiston/@41.2932836,69.2215172,17z/data=!4m5!3m4!1s0x38ae8a4b734fa5a3:0xd308859645f16a54!8m2!3d41.2932803!4d69.2215222?entry=ttu&g_ep=EgoyMDI0MTExMC4wIKXMDSoASAFQAw%3D%3D"
                    className="appeal-link"
                  >
                    <i className="ri-map-pin-2-fill"> </i>
                    Muqumiy street 55, Toshkent city
                  </a>
                  <a href="tel:" className="appeal-link">
                    <i className="ri-phone-fill"> </i>
                    +1 (7635) 547-12-97
                  </a>
                  <a href="mailto:iifa@edu.com" className="appeal-link">
                    <i className="ri-mail-fill"> </i>
                    iifa@edu.com
                  </a>
                </div>
                <div className="map-wrapper">
                  <iframe src={mapUrl} allowFullScreen={true} loading="lazy"></iframe>
                </div>
              </div>
              <div className="appeal-submit appeal-submit-mobile">
                <button onClick={fillingHandle} className="appeal-cancel">
                  Cancel
                </button>
                <button type="submit" className="appeal-confirm">
                  Confirm
                </button>
              </div>
            </div>
          </>
        )
      )}
      {certificate && (
        <div className="container cetificate-wrapper">
          <div className="certificates">
            <p className="course-name">My certificates</p>
            <div className="certificate-card">
              <img
                id="certificate-img"
                className="cert-img"
                src={CertificateImg}
                alt="Certificate"
              />
              <div className="cert-down">
                <p className="down-text">Download file</p>
                <button onClick={downloadPDF} className="down-btn">
                  <i className="ri-folder-line"></i> Certificate.pdf
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sertificate;
