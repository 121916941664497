import React, { useEffect, useState } from "react";
import PartnerImg from "../../images/employee.png";
import PartnerImg3 from "../../images/announcement.png";
import PartnerImg2 from "../../images/money.png";
import Sell from "../../images/sell.png";
import "./Partners.css";
import "../Home/Home.css"
import axios from "axios";
import { API } from "../../constants";
import api from "../../Components/RefreshToken/RefreshToken";
import { useTranslation } from "react-i18next";

const AboutUs = () => {

  const [aboutData, setAboutData] = useState()
  const {t} = useTranslation()
  const lang = localStorage.getItem('i18nextLng')

  useEffect(() => {
    api.get(`${API}/management/about-us`, {
      headers: { 'Accept-Language': lang }  
    }).then((res) => {
      setAboutData(res.data)
    }).catch((err) => {
      console.log("bu errororooo",err)
    })
  }, [lang])

  return (
    <>
      <div className="container">
        <div className="partners-left-btn">
          <button className="partners-info space_grotesk">
           {t('aboutPlatform')}
          </button>
        </div>
        {aboutData?.map((item) => (
          <div className="partners-block">
            <div className="partners-left">
              <h5 className="partners-left-title">{item?.title}</h5>
              <p className="partners-desc first-desc">
                {item?.description}
              </p>
            </div>
            <div className="partners-right employee-img">
              <img src={`${API}/${item?.photo}`} alt="image" className="partners-img" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AboutUs;
