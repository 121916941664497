import React, { useEffect, useState } from 'react'
import Layout from '../../../../Components/StudentDashboard/Layout'
import { useParams } from 'react-router-dom';
import { API } from '../../../../constants';
import axios from 'axios';
import CourseDetail from '../../../Detail';
import Lessons from '../../../Courses/Courses';
import EnrolledCourseDetails from "../../../EnrroledCourse/Courses";
import api from '../../../../Components/RefreshToken/RefreshToken';

export default function EnrollMentDetail() {
  const [enroll, setEnroll] = useState()
  const { enrollmentId } = useParams();

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      api.get(`${API}/student/learning/${enrollmentId}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }).then((response) => {
        setEnroll(response.data);
      })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <Layout>
      <EnrolledCourseDetails courseId={enroll?.id} />
    </Layout>
  )
}
