import React, { useState } from "react";
import './layout.css'
import Sidebar from "../Sidebar";


const Layout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev);
    };

    return (
        <div className="layout">

            <Sidebar isSidebarOpen={isSidebarOpen} />

            <main className="content">
                {children}
            </main>
        </div>
    );
};

export default Layout;
