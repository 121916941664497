import React, { useEffect, useState } from "react";
import "./ListCourses.css";
import { useNavigate } from "react-router-dom";
import { data } from "./data";
import axios from "axios";
import { API } from "../../constants";
import PlayImg from "../../images/Polygon.svg";
import LeftArrow from "../../images/left-arrow.svg";
import ImageBlock from "../../images/BookImg.png";
import RightArrow from "../../images/right-arrow.svg";
import { toast } from "react-toastify";
import api from "../../Components/RefreshToken/RefreshToken";
import { useTranslation } from "react-i18next";

const ListCourses = () => {
  const coursesData = data;
  const [currentPage, setCurrentPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [coursesPerPage, setCoursesPerPage] = useState(8);
  const totalPages = Math.ceil(coursesData.length / coursesPerPage);
  const navigate = useNavigate();
  const {t, i18next} = useTranslation()

  useEffect(() => {
    api.get(`${API}/courses/`).then((response) => {
      setCourses(response.data);
      console.log("bu kurslar",response.data);
    }).catch((error) => {
      toast.error(`Error fetching courses: ${error?.message || "Unknown error"}`);
      console.error("Error fetching courses: ", error);
    });
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePlayClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCoursesPerPage(4);
      } else {
        setCoursesPerPage(8);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const currentCourses = courses.slice(
  //   (currentPage - 1) * coursesPerPage,
  //   currentPage * coursesPerPage
  // );

  return (
    <div className="courses container">
      <h3 className="courses-title">{t('coursesTitle')}</h3>
      <div className="courses-block">
        {courses.map((course) => (
          // console.log(course)
          <div className="courses-card" key={course.id}>
            <div className="course-img-box">
              <img className="course-img" src={`${API}/${course?.photo}`} alt="img" />
              <div className="course-language">
                {course?.language?.charAt(0).toUpperCase() + course?.language?.slice(1)}
              </div>
            </div>
            <div className="pricee">
              <p className="price">{course.price} {t('price')}</p>
            </div>
            <h3 className="course-name">{course.title}</h3>
            <p className="course-author">{course?.mentor?.first_name} {course?.mentor?.last_name}</p>
            <p className="course-desc" dangerouslySetInnerHTML={{ __html: course?.description}}></p>
            <div className="card-bottom">
              <button
                className="button play-btn"
                onClick={() => (!course.buy ? handlePlayClick(course.id) : navigate('/payment'))}
              >
                {!course.buy ? "Details" : "Details"}
                {/* <img src={PlayImg} alt="play img" className="play-icon" /> */}
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <span className="page-label">Page</span>
        <button
          className="page-arrow-left page-arrow-left-after"
          onClick={() =>
            handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
          }
          disabled={currentPage === 1}
        >
          <img className="page-arrow-img" src={LeftArrow} alt="left arrow" />
        </button>
        <div className="page-number">{currentPage}</div>
        <button
          className="page-arrow-right page-arrow-right-after"
          onClick={() =>
            handlePageChange(
              currentPage < totalPages ? currentPage + 1 : totalPages
            )
          }
          disabled={currentPage === totalPages}
        >
          <img className="page-arrow-img" src={RightArrow} alt="right arrow" />
        </button>
        <select
          className="page-select"
          onChange={(e) => handlePageChange(Number(e.target.value))}
        >
          <option selected disabled>
            {totalPages}
          </option>
          {Array.from({ length: totalPages }, (_, i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default ListCourses;
