import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../images/logo.svg";
import SignIn from "../../images/sign.svg";
import CloseImg from "../../images/close-nav.png";
import CloseImage from "../../images/fi_x.svg";
import { ADMIN_URL, API } from "../../constants";
import PhoneNumberInput from "../Inputs/PhoneNumberInput";
import { maskNumber } from "../../helpers/maskNumber";
import { toast } from "react-toastify";
import api from "../RefreshToken/RefreshToken";
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [user, setUser] = useState(null);

    const [countryCode, setCountryCode] = useState("+998");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [gender, setGender] = useState("");
    const [laungageReg, setLaungageReg] = useState("");



    const [otp, setOtp] = useState({ visible: false, code: "", correctOtp: "" });
    const [activeNav, setActiveNav] = useState(false);
    const [register, setRegister] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const { t, i18n } = useTranslation();
    const laungage = localStorage.getItem('i18nextLng')

    useEffect(() => {
        if (localStorage.getItem("token")) {
            api
                .get(`${API}/profile`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                })
                .then((response) => setUser(response.data))
                .catch((error) => console.error("bu profile err", error));
        }
    }, [isModalOpen]);

    const handleLogout = () => {
        if (!window.confirm("Are you sure you want to log out?")) return;
        localStorage.removeItem("token");
        setUser(null);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (register) {
            api
                .post(`${API}/register`, {
                    phone_number: countryCode + phoneNumber,
                    first_name: firstName,
                    last_name: lastName,
                    middle_name: middleName,
                    gender: gender,
                    exam_language: laungageReg,
                    email: email,
                    password,
                })
                .then((response) => {
                    setOtp({ ...otp, visible: true });

                    api.post(`${API}/send-otp`, {
                        "phone_number": `${countryCode}${phoneNumber}`
                    }
                    ).then((response) => {
                        toast.success("Verification successfully sent!");
                    })
                    // localStorage.setItem("token", response.data.access_token);
                    // setIsModalOpen(false);
                    // navigate("/");
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.detail);
                });
        } else {

            api
                .post(`${API}/login`, {
                    phone_number: countryCode + phoneNumber,
                    password,
                })
                .then((response) => {
                    localStorage.setItem("token", response.data.access_token);
                    localStorage.setItem("studentRefreshToken", response.data.refresh_token)
                    setIsModalOpen(false);
                    navigate("/");
                })
                .catch((error) => {
                    toast.error("Check your credentials!");
                    console.error("Error:", error.response ? error.response.data : error.message);
                });
        }
    };

    const handleOtpSubmit = (event) => {
        event.preventDefault();

        api.post(`${API}/verify-otp`, {
            "phone_number": `${countryCode}${phoneNumber}`,
            "otp": otp?.code
        }).then(() => {
            api
                .post(`${API}/login`, { phone_number: countryCode + phoneNumber, password })
                .then((response) => {
                    localStorage.setItem("token", response.data.access_token)
                    localStorage.setItem("studentRefreshToken", response.data.refresh_token)
                    toast.success("Loged in!");
                    setIsModalOpen(false);
                    setPassword("");
                    setPhoneNumber("");
                    setOtp({ visible: false, code: "", correctOtp: "" });
                })
                .catch((error) => {
                    toast.error("Check your credentials!");
                    console.error(error);
                });
        }).catch((error) => {
            setOtpError(true);
        })
    };
    console.log(user)
    const handleChangeTab = (type) => {
        setRegister(type === "register");
        setPhoneNumber(null);
        setPassword("");
    }

    if (location.pathname.startsWith("/student")) return null;

    const handlechangeLanguage = (e) => {
        const selectedLanguage = e.target.value;
        i18n.changeLanguage(selectedLanguage)
    }

    const navItems = [
        { key: "home", label: t("home") },
        { key: "about", label: t("about") },
        { key: "partners", label: t("partners") },
        { key: "courses", label: t("courses") }
    ];


    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    return (
        <div className="container">
            <div className="navbar">
                <div onClick={() => setActiveNav(!activeNav)} className="toggle">
                    <span className="toggle-line"></span>
                    <span className="toggle-line2"></span>
                    <span className="toggle-line"></span>
                </div>
                <a href="/" className="logo-link">
                    <img src={Logo} alt="Logo" />
                </a>
                <ul className={`nav-list ${activeNav ? "nav-list--active" : ""}`}>
                    <div className="list-logo-block">
                        <a href="/" className="logo-link">
                            <img src={Logo} alt="Logo" />
                        </a>
                        <img className="close-btn" onClick={() => setActiveNav(false)} src={CloseImg} alt="Close" />
                    </div>
                    {navItems.map((item, index) => (
                        <li key={index} className="nav-list-item">
                            <NavLink
                                to={item.key === "home" ? "/" : `/${item.key}`}
                                onClick={() => setActiveNav(false)}
                                className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
                            >
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
                    <div className="luanguage-block">
                        <select onChange={handlechangeLanguage} value={laungage} className="luanguag-select select-nav">
                            <option value="uz">Uz</option>
                            <option value="ru">Ru</option>
                            <option value="en">En</option>
                        </select>
                    </div>

                </ul>
                <div className="sign-icon">
                    {!!user ? (
                        <Link to="/student/info">
                            <i class="ri-user-fill"></i>
                        </Link>
                    ) : (
                        <i onClick={() => setIsModalOpen(true)} class="ri-user-fill"></i>
                    )}

                </div>
                <div className="nav-right">
                    {!!user ? (
                        <NavLink className="hide-userName" to="/student/info">
                            <button className="button">{user.first_name} {user.last_name}</button>
                        </NavLink>
                    ) : (
                        <button onClick={() => setIsModalOpen(true)} className="button">{t('loginBtn')}</button>
                    )}
                </div>
            </div>

            {isModalOpen && (
                <div className="modalLogin">
                    <div className="modalLogin-content">
                        <div className="modalLogin-title">
                            <h1 className="login-title"
                                style={{ marginBottom: "10px" }}>{otp.visible ? "Enter code" : register ? "Register" : "Sign in"}</h1>
                            <div onClick={() => setIsModalOpen(false)} className="close">
                                <img src={CloseImage} alt="Close" />
                            </div>
                        </div>

                        {!otp.visible && (
                            <div className="tab-auth">
                                <div onClick={() => handleChangeTab("login")}
                                    className={register ? "tab-auth-item" : "tab-auth-item active"}>
                                    <p>Login</p>
                                </div>
                                <div onClick={() => handleChangeTab("register")}
                                    className={register ? "tab-auth-item active" : "tab-auth-item"}>
                                    <p>Register</p>
                                </div>
                            </div>
                        )}

                        {otp.visible ? (
                            <form onSubmit={handleOtpSubmit}>
                                <input
                                    required
                                    name="otp"
                                    className="modalLogin-input input"
                                    placeholder="Enter OTP Code"
                                    type="text"
                                    value={otp.code}
                                    onChange={(e) => setOtp({ ...otp, code: e.target.value })}
                                />
                                <div className="otp-info">
                                    <p>An SMS with a confirmation code has been sent to this number</p>
                                    <p className="otp-info-number">{maskNumber(phoneNumber)}</p>
                                    <p className="otp-timer">00:59</p>
                                </div>
                                {otpError && <p style={{ color: "red" }}>Invalid OTP. Please try again.</p>}
                                <button type="submit" className="submit otp-btn">Confirm</button>
                            </form>
                        ) : register ? (
                            <form onSubmit={handleSubmit}>
                                <input
                                    required
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    name="firstName"
                                    className="modalLogin-input input"
                                    placeholder="First name"
                                    type="text"
                                />
                                <input
                                    required
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    name="lastName"
                                    className="modalLogin-input input"
                                    placeholder="Last name"
                                    type="text"
                                />
                                <input
                                    required
                                    value={middleName}
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    name="middleName"
                                    className="modalLogin-input input"
                                    placeholder="Middle name"
                                    type="text"
                                />
                                <div>
                                    <div>Choose your gender</div>
                                    <label className="gender-input">
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="M"
                                            checked={gender === "M"}
                                            onChange={handleGenderChange}
                                        />
                                        Male
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="F"
                                            checked={gender === "F"}
                                            onChange={handleGenderChange}
                                        />
                                        Female
                                    </label>
                                </div>
                                <select
                                    className="modalLogin-input input"
                                    value={laungageReg}
                                    onChange={(e) => setLaungageReg(e.target.value)}
                                >
                                    <option hidden>What language do you want to study in?</option>
                                    <option value="uz">O'zbek</option>
                                    <option value="ru">Русский</option>
                                    <option value="uk">Украинский</option>
                                    <option value="en">English</option>
                                </select>
                                <PhoneNumberInput countryCode={countryCode} setCountryCode={setCountryCode}
                                    phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                                <input
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    className="modalLogin-input input"
                                    placeholder="user@example.com"
                                    type="email"
                                />
                                <input
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                    className="modalLogin-input input"
                                    placeholder="Password"
                                    type="password"
                                />
                                <div className="submit-block">
                                    <button onClick={() => setIsModalOpen(false)} type="button"
                                        className="cancel">Cancel
                                    </button>
                                    <button type="submit" className="submit">Confirm</button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <PhoneNumberInput countryCode={countryCode} setCountryCode={setCountryCode}
                                    phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                                <input
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                    className="modalLogin-input input"
                                    placeholder="Password"
                                    type="password"
                                />
                                <div className="submit-block">
                                    <button onClick={() => setIsModalOpen(false)} type="button"
                                        className="cancel">Cancel
                                    </button>
                                    <button type="submit" className="submit">Confirm</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
