import React from 'react'
import Layout from '../../../Components/StudentDashboard/Layout'
import PaymentComponent from '../../../Components/StudentDashboard/Payment'

export default function StudendDashboardPayment() {
    return (
        <Layout>
            <PaymentComponent />
        </Layout>
    )
}
