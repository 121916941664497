import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import Robot from "../../images/robot.png";
import SendImg from "../../images/Send.svg";
import { marked } from "marked";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import PlayAudio from '../../images/Animation.gif'
import LoadingGif from '../../images/Animation2.gif'
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import QuizModal from "../QuizModal/QuizModal";
import remarkGfm from "remark-gfm";


const TextRenderer = ({ text, className, children }) => {
    // const formattedText = text.replace(/\n/g, '\n');
    // const formattedText = text || ""
    const cleanedText = text.replace(/`/g, '');

    return (
        <div className={className}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{cleanedText}</ReactMarkdown>
            {children}
        </div>
    );
};

const Chat = ({ socket = "nazorat_test", textOfVideo, courseLanguage }) => {
    const location = useLocation()
    const testLanguage = location.state?.courseLanguage;

    const initialSocketUrl = `wss://talimbot.diyoraai.uz/chatbot/${socket}?language=${testLanguage}`;
    const [socketUrl, setSocketUrl] = useState(initialSocketUrl);
    const [messages, setMessages] = useState([]);
    const [active, setActive] = useState(false);
    const [input, setInput] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [isSent21, setIsSent21] = useState(false);
    const [textForResult, setTextForResult] = useState(null);
    const [myAnswersList, setMyAnswersList] = useState([]);
    const [messagesCount, setMessagesCount] = useState(null);
    const [isSentResultMessage, setIsSentResultMessage] = useState(false);
    const [isRecording, setIsRecording] = useState(false)
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [sectionBScores, setSectionBScores] = useState([]);
    const [feedbackMessages, setFeedbackMessages] = useState([]);
    const [testScore, setTestScore] = useState(0);


    const [isTestMode, setIsTestMode] = useState(
        socket === "nazorat_test" || socket === "nazorat_savol"
    );

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
    const chatBodyRef = useRef(null);

    useEffect(() => {
        const storedMessages =
            JSON.parse(localStorage.getItem("chatMessages")) || [];
        setMessages(storedMessages);
    }, []);

    const conditionToStartTestSavol = (messages[messages?.length - 1]?.text?.includes("Correct Answers") && messages[messages.length - 1]?.text?.includes("Incorrect Answers")) ||
        (messages[messages.length - 1]?.text?.includes("ответили") && messages[messages?.length - 1]?.text?.includes("правильно") && messages[messages.length - 1]?.text?.includes("ответы") && messages[messages.length - 1]?.text?.includes("вопросов"));

    const lastMessageIdentifier = messages[messages.length - 1];

    const isOptionalTestsOver = lastMessageIdentifier?.text?.includes("Test tugadi") ||
        lastMessageIdentifier?.text?.includes("You have completed") ||
        lastMessageIdentifier?.text?.includes("I have completed") ||
        lastMessageIdentifier?.text?.includes("Thank you for") ||
        lastMessageIdentifier?.text?.includes("Test 1 is complete") ||
        lastMessageIdentifier?.text?.includes("Your test is completed") ||
        lastMessageIdentifier?.text?.includes("barcha javoblaringizni tekshirdik") ||
        lastMessageIdentifier?.text?.includes("Testni tugatdingiz") ||
        lastMessageIdentifier?.text?.includes("javoblaringizni tekshirish") ||
        lastMessageIdentifier?.text?.includes("end of the test") ||
        lastMessageIdentifier?.text?.includes("If you would like to") ||
        lastMessageIdentifier?.text?.includes("sizga javoblaringiz haqida ma'lumot") ||
        lastMessageIdentifier?.text?.includes("javoblar sonini so'rang") ||
        lastMessageIdentifier?.text?.includes("answers or further assistance") ||
        lastMessageIdentifier?.text?.includes("You've completed") ||
        lastMessageIdentifier?.text?.includes("Спасибо") ||
        lastMessageIdentifier?.text?.includes("Тесты завершены") ||
        lastMessageIdentifier?.text?.includes("завершены") ||
        lastMessageIdentifier?.text?.includes("Тест завершен") ||
        lastMessageIdentifier?.text?.includes("Тестирование завершено") ||
        lastMessageIdentifier?.text?.includes("завершено") ||
        lastMessageIdentifier?.text?.includes("Вы завершили") ||
        lastMessageIdentifier?.text?.includes("Тесты закончены") ||
        lastMessageIdentifier?.text?.includes("завершили")


    const isAllTestsOver = (
        (lastMessageIdentifier?.text?.includes("Problem 1") && lastMessageIdentifier?.text?.includes("Problem 2"))
        && (lastMessageIdentifier?.text?.includes("Evaluation of Your Answer")
            || lastMessageIdentifier?.text?.includes("answered both")
            || lastMessageIdentifier?.text?.includes("both problems")
            || lastMessageIdentifier?.text?.includes("Final Evaluation")
        ))
        && messages?.length > 20;

    const isSectionB = messages?.length > 30 &&
        (lastMessageIdentifier?.text?.includes("SECTION B") ||
            lastMessageIdentifier?.text?.includes("Section B") ||
            lastMessageIdentifier?.text?.includes("РАЗДЕЛ B") ||
            lastMessageIdentifier?.text?.includes("Раздел B") ||
            lastMessageIdentifier?.text?.includes("Задача Раздела B") ||
            lastMessageIdentifier?.text?.includes("Задача раздела B") ||
            lastMessageIdentifier?.text?.includes("Задача") ||
            lastMessageIdentifier?.text?.includes(" Раздела B"));

    const isProblemsStarterMessage = (lastMessageIdentifier?.text?.includes("Correct answer") &&
        lastMessageIdentifier?.text?.includes("My answer")) ||
        (lastMessageIdentifier?.text?.includes("Правильные ответы") &&
            lastMessageIdentifier?.text?.includes("Мои ответы"));

    const isTwentythQuestion = messages[messages.length - 1]?.text?.includes("Question 20") || messages[messages.length - 1]?.text?.includes("Question-20") || messages[messages.length - 1]?.text?.includes("20 Question") || messages[messages.length - 1]?.text?.includes("Test 20") || messages[messages.length - 1]?.text?.includes("Test 20.");

    const isResult = messages[messages.length - 1]?.text?.includes("Correct Answer") && messages[messages.length - 1]?.text?.includes("My answer");
    const isTwentyFirstMessage = (messages[messages.length - 1]?.text?.includes("Thank you") && (messages[messages.length - 1]?.text?.includes("the answers") || messages[messages.length - 1]?.text?.includes("your answers")) && (messages[messages.length - 1]?.text?.includes("questions incorrectly") || messages[messages.length - 1]?.text?.includes("feel free to ask") || messages[messages.length - 1]?.text?.includes("let me know"))) || (messages[messages.length - 1]?.text?.includes("Спасибо за") && (messages[messages.length - 1]?.text?.includes("все вопросы") || messages[messages.length - 1]?.text?.includes("your answers")) && (messages[messages.length - 1]?.text?.includes("questions incorrectly") || messages[messages.length - 1]?.text?.includes("feel free to ask") || messages[messages.length - 1]?.text?.includes("дайте мне знать")));


    useEffect(() => {
        if (isSectionB) {
            setMessagesCount(messages?.length);
        }
    }, [isSectionB])

    useEffect(() => {
        if (isSentResultMessage) {
            setMessages((prev) => {
                return prev?.map((item, index) => {
                    if (index === messages?.length - 1) {
                        return { ...item, visible: true }
                    }

                    return item;
                })
            });
        }
    }, [isSentResultMessage])

    useEffect(() => {
        if (!!messagesCount && messages?.length === (messagesCount + 1)) {
            const dateTime = Date.now();
            const newMessage = {
                sender: 'user',
                text: "Preparing your result...",
                visible: true,
                time: new Date(dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                dateTime,
            };
            setMessages([...messages, newMessage]);
            const resultMessage = `
                Please indicate how many points I have completed in the questions a, b, c with my answers and calculate my total score.
                format: Ball a:(my score) b: (my score) c: (my score)
                total: (my score)/60
            `;
            sendMessage(resultMessage);
            setIsSentResultMessage(true);
        }
    }, [messages]);

    useEffect(() => {
        if (isSentResultMessage) {
            const lastBotMessage = messages[messages.length - 1]?.text;
            const totalScoreMatch = lastBotMessage?.match(/total:\s*(\d+)\/\d+/i);
            if (totalScoreMatch && totalScoreMatch[1]) {
                const totalScore = parseInt(totalScoreMatch[1], 10);
                setSectionBScores((prevScores) => [...prevScores, { score: totalScore }]);
            }
        }
    }, [isSentResultMessage, messages]);

    useEffect(() => {
        //         const text = `Correct answer: a,b,a,c,c,b,c,a,c,a,b,a,c,b,c,a,c,c,b,b
        // My answer: a,b,d,a,c,b,d,a,d,b,d,a,b,c,a,c,c,b,b,d,a`;
        //
        //         text.split("\n")?.map((t) => {
        //             console.log(t?.split(":")[1]);
        //         });

        if (isProblemsStarterMessage) {
            messages[messages.length - 1]?.text?.split("\n")?.map((t) => {
                t?.split(":")[1]?.split(",")?.map(letter => {
                    console.log(letter?.trim());
                })
            });
        }
    }, [isProblemsStarterMessage]);

    useEffect(() => {
        if (isAllTestsOver) {
            const pointsOfFirstQuestion = messages[messages.length - 1]?.text?.split("Problem 1")[1]?.match(/\d+\/\d+/)[0]?.split('/')?.[0];
            const pointsOfSecondQuestion = messages[messages.length - 1]?.text?.split("Problem 2")[1]?.match(/\d+\/\d+/)[0]?.split('/')?.[0];

            localStorage.setItem("scores", `${pointsOfFirstQuestion}/${pointsOfSecondQuestion}`);

            navigate('/student/courses/quiz/results');
        }
    }, [isAllTestsOver]);

    useEffect(() => {
        if (conditionToStartTestSavol || isProblemsStarterMessage) {
            setSocketUrl(`wss://talimbot.diyoraai.uz/chatbot/nazorat_savol?language=${testLanguage}`);
            setIsTestMode(true);
        }
    }, [messages, conditionToStartTestSavol, isProblemsStarterMessage]);

    // textOfVideo = socket === "nazorat_savol" ? `Based on this information, you must create one problem for me for the type of question SECTION B.
    //     The SECTION B problem should meet the following requirements, similar to the sample problem.
    //     Requirements: "Section B (30 points):
    //         o Consists of three tasks (a, b, c) based on a complex audit situation
    //         o A question may include several sub-questions covering various aspects of the audit
    //         o Candidates must demonstrate the ability to apply knowledge and skills to analyze the situation, identify risks, and develop audit procedures."
    //     You do not need to give me any feedback on whether I answered the question correctly or incorrectly after I have answered it. You should tell me after I have asked it.
    //     You will not ask questions that are unrelated to this information and you will not answer any of my questions.
    //     Your main task is to give me the problem and get my answer.` : textOfVideo;

    useEffect(() => {
        if (socket === "nazorat_savol") {
            if (testLanguage === "ru") {
                textOfVideo = `
                На основе этой информации вам необходимо создать одну задачу для типа вопроса РАЗДЕЛ B.
                Задача РАЗДЕЛА B должна соответствовать следующим требованиям, аналогичным образцу задачи.
                Требования:
                "Раздел B (60 баллов):
                    o Состоит из трех заданий (a, b, c), основанных на сложной аудиторской ситуации.
                    o Вопрос может включать несколько подпунктов, охватывающих различные аспекты аудита.
                    o Кандидаты должны продемонстрировать способность применять знания и навыки для анализа ситуации, выявления рисков и разработки аудиторских процедур."
                Вы не должны давать мне никакой обратной связи о том, правильно или неправильно я ответил на вопрос, после того как я отвечу. Вы должны сказать мне после того, как я сам спрошу.
                Вы не будете задавать вопросы, не относящиеся к данной информации, и не будете отвечать на любые мои вопросы.
                Ваша основная задача – дать мне задачу и получить мой ответ.
            `;
            } else {
                textOfVideo = `
                Based on this information, you must create one problem for me for the type of question SECTION B.
                The SECTION B problem should meet the following requirements, similar to the sample problem.
                Requirements:
                "Section B (60 points):
                    o Consists of three tasks (a, b, c) based on a complex audit situation.
                    o A question may include several sub-questions covering various aspects of the audit.
                    o Candidates must demonstrate the ability to apply knowledge and skills to analyze the situation, identify risks, and develop audit procedures."
                You do not need to give me any feedback on whether I answered the question correctly or incorrectly after I have answered it. You should tell me after I have asked it.
                You will not ask questions that are unrelated to this information and you will not answer any of my questions.
                Your main task is to give me the problem and get my answer.
            `;
            }
        } else {
            textOfVideo = textOfVideo;
        }
    }, [socket, testLanguage])

    if (isOptionalTestsOver && !isSent21) {
        const correctAnswers = "a,b,a,d,...";
        const myAnswers = myAnswersList.join(",");

        if (testLanguage === "ru") {
            sendMessage(`
                Отправь мне ответы на 20 тестов в следующем формате, без лишних деталей.
                Правильные ответы: (${correctAnswers})
                Мои ответы: (${myAnswers})
            `);

        } else {
            sendMessage(`
                Send me the 20 tests answers in the following format, without any extra details.
                Correct answer: (${correctAnswers})
                My answer: (${myAnswers});;
            `);
        }
        setIsSent21(true);
    }

    console.log("text titliii", testLanguage)

    const formatDate = () => {
        const date = new Date();
        const day = date.getDate();
        const months = [
            "yanvar",
            "fevral",
            "mart",
            "aprel",
            "may",
            "iyun",
            "iyul",
            "avgust",
            "sentyabr",
            "oktyabr",
            "noyabr",
            "dekabr",
        ];
        return `${day} ${months[date.getMonth()]} ${date.getFullYear()}`;
    };

    const handleSend = () => {
        if (messages?.length === messagesCount) {
            if (messages[messages?.length - 1]?.text <= input?.length) {
                toast.warning("Your answer should be longer!");
                return;
            }
        }

        if (input.trim()) {
            const dateTime = Date.now();
            const newMessage = {
                sender: 'user',
                text: input,
                visible: true,
                time: new Date(dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                dateTime,
            };

            if (["a", "b", "c", "d", "A", "B", "C", "D"].includes(input.trim())) {
                setMyAnswersList((prev) => [...prev, input.trim()]);
            }

            setActive(true);
            setMessages((prev) => {
                if (prev[messages?.length - 1]?.text !== "Preparing your result...") {
                    return [...prev, newMessage]
                }

                return [...prev, { ...newMessage, visible: false }];
            });
            if (isTwentyFirstMessage) {
                const myAnswers = myAnswersList.join(",");
                sendMessage(`
                            Send me the 20 tests answers in the following format. without any extra details.
                            Correct answer :(all Correct answer latters (example:a,b,a,d...))
                            My answer :(${myAnswers})
                       `);
            } else {
                sendMessage(`${input}`);
            }

            setInput('');
            setIsTyping(true);
        }
    };

    const [tests, setTests] = useState([]);

    const handleStartTest = () => {
        setClicked(true);
        if (messages.length > 20) {
            if (testLanguage === "ru") {
                sendMessage(`
                    На основе этой информации вам необходимо создать одну задачу для типа вопроса РАЗДЕЛ B.
                    Задача РАЗДЕЛА B должна соответствовать следующим требованиям, аналогичным образцу задачи.
                    Требования:
                    "Раздел B (60 баллов):
                        o Состоит из трех заданий (a, b, c), основанных на сложной аудиторской ситуации.
                     o Вопрос может включать несколько подпунктов, охватывающих различные аспекты аудита.
                        o Кандидаты должны продемонстрировать способность применять знания и навыки для анализа ситуации, выявления рисков и разработки аудиторских процедур."
                    Вы не должны давать мне никакой обратной связи о том, правильно или неправильно я ответил на вопрос, после того как я отвечу. Вы должны сказать мне после того, как я сам спрошу.
                    Вы не будете задавать вопросы, не относящиеся к данной информации, и не будете отвечать на любые мои вопросы.
                    Ваша основная задача – дать мне задачу и получить мой ответ.
                `);
                setIsTestMode(false);
            } else {
                sendMessage(`
                    Based on this information, you must create one problem for me for the type of question SECTION B.
                    The SECTION B problem should meet the following requirements, similar to the sample problem.
                    Requirements:
                    "Section B (60 points):
                        o Consists of three tasks (a, b, c) based on a complex audit situation
                        o A question may include several sub-questions covering various aspects of the audit
                        o Candidates must demonstrate the ability to apply knowledge and skills to analyze the situation, identify risks, and develop audit procedures."
                    You do not need to give me any feedback on whether I answered the question correctly or incorrectly after I have answered it. You should tell me after I have asked it.
                    You will not ask questions that are unrelated to this information and you will not answer any of my questions.
                    Your main task is to give me the problem and get my answer.
                `);
                setIsTestMode(false);
            }
            return;
        }
        sendMessage("Start test.");
        setIsTestMode(false);
        setActive(true);
    };


    const [sectionBMessages, setSectionBMessages] = useState([]);

    useEffect(() => {
        if (clicked && lastMessage?.data) {
            const botMessage = {
                text: lastMessage.data,
            };
            setSectionBMessages((prevMessages) => [...prevMessages, botMessage]);
            setClicked(false);
        }
    }, [lastMessage, socket]);

    console.log("section B messages", sectionBMessages);

    useEffect(() => {
        if (lastMessage?.data && socket === "nazorat_test") {
            const botMessage = lastMessage.data;
            setTests((prevTests) => [...prevTests, botMessage]);
        }
    }, [lastMessage]);
    const [sectioBQuestion, setSectionBQuestion] = useState([]);
    useEffect(() => {
        if (socket === "nazorat_savol") {
            if (lastMessage?.data) {
                const botMessage = lastMessage.data;
                setSectionBQuestion((prevTests) => [...prevTests, botMessage]);
            }
        }
    }, [lastMessage]);

    console.log("section B", sectioBQuestion);

    useEffect(() => {
        if (socket === "nazorat_test" && messages.length === 40) {
            sendMessage(`Please send me the correct answers for the 20 tests in the following format.
Format: correct answers:(a,b,c,.....)

For each of the 20 correct answers you have sent, please provide feedback in the following "feedbackformat".
feedbackformat:
1.feedback
2.feedback
...`)
        }
    }, [messages]);

    useEffect(() => {
        if (lastMessage?.data && socket === "nazorat_test" && messages.length === 42) {
            const newMessage = lastMessage.data;

            setFeedbackMessages((prevMessages) => [...prevMessages, newMessage]);
        }
    }, [lastMessage, messages, socket]);

    // console.log("tests", tests);
    console.log("bu feedbeklar", feedbackMessages);

    function replaceMyAnswers(text, answersArray) {
        const formattedAnswers = answersArray.join(", ");

        return text.replace(/My answer:\s*[a-z](?:,\s*[a-z])*/i, `My answer: ${formattedAnswers}`);
    };

    useEffect(() => {
        const ready = messages?.map((message) => {
            if (message.text.includes("Correct answer") && message.text.includes("My answer")) {
                return {
                    ...message,
                    text: replaceMyAnswers(message.text, myAnswersList),
                }
            }

            return message;
        });

        setMessages(ready);
    }, [(lastMessage?.data?.includes("Correct answer") && (lastMessage?.data?.includes("My answer")))]);

    useEffect(() => {
        if (active && lastMessage !== null) {
            const dateTime = Date.now();
            const botMessage = {
                sender: "bot",
                visible: true,
                text: lastMessage.data,
                time: new Date(dateTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
                dateTime,
            };

            setMessages((prev) => {
                const messageExists = prev.some((msg) => msg.text === botMessage.text);

                if (messageExists) return prev;

                if (prev[prev.length - 1]?.text !== "Preparing your result...") {
                    return [...prev, botMessage];
                }

                return [...prev, { ...botMessage, visible: false }];
            });

            setIsTyping(false);
        }

        setClicked(false);
    }, [lastMessage]);



    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    const reconnectWebSocket = () => {
        setSocketUrl("");
        setTimeout(() => setSocketUrl(initialSocketUrl), 100);
    };

    const isConnectionOpen = readyState === ReadyState.OPEN;

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];
    let templateOfAI = "";
    if (courseLanguage === "ru") {
        templateOfAI = `
        Вы — искусственный интеллект по имени Diyora.
Ваша задача — отвечать на вопросы студента только на русском языке.
Не отвечайте на других языках, основываясь на приведенной выше информации,
если студент не понимает тему.
Вам категорически запрещено предоставлять информацию, выходящую за рамки указанной выше.
    `
    } else {
        templateOfAI = `
        You are an artificial intelligence named Diyora. 
        Your task is to answer the student's questions only in English, 
        do not answer any other language, based on the information above if 
        the student does not understand the topic. You are absolutely not allowed to provide information outside of 
        the information above.;
    `
    }
    useEffect(() => {
        if (!!textOfVideo && isTestMode) {
            sendMessage(JSON.stringify(`${textOfVideo}`));
        }

        if (!!textOfVideo && socket === 'help') {
            sendMessage(JSON.stringify(`Information: ${textOfVideo}; Task: ${templateOfAI}`));
        }
    }, [connectionStatus === ReadyState.OPEN, textOfVideo]);

    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;
            audioChunksRef.current = [];
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };
            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                setAudioBlob(audioBlob);
                sendRecord(audioBlob);
            };
            mediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error("Ovoz yozib olishda xatolik:", error);
        }
    };
    const [audioText, setAudioText] = useState([])
    const sendRecord = (blob) => {
        const formData = new FormData();
        formData.append("file", blob);

        console.log("audio blob", blob);

        axios.post("https://sttapi.diyoraai.uz/stt", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "accept-language": courseLanguage,
            },
        }).then((response) => {
            setAudioText(response?.data);
        }).catch((err) => {
            console.log(err);
        });
    };
    const transcriptRef = useRef(audioText?.transcript);

    useEffect(() => {
        transcriptRef.current = audioText?.transcript;
    }, [audioText.transcript]);
    const handleStopAndSend = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
        setTimeout(() => {
            const latestTranscript = transcriptRef.current;
            if (latestTranscript && latestTranscript.trim()) {
                const dateTime = Date.now();
                const newMessage = {
                    sender: 'user',
                    text: latestTranscript,
                    visible: true,
                    time: new Date(dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    dateTime,
                };
                setActive(true);
                setMessages((prev) => {
                    const lastMessage = prev[prev.length - 1];
                    if (lastMessage?.text !== "Preparing your result...") {
                        return [...prev, newMessage];
                    }
                    return [...prev, { ...newMessage, visible: false }];
                });

                if (!isTwentyFirstMessage) {
                    sendMessage(`${latestTranscript}`);
                }

                setIsTyping(true);
            }
        }, 1000);
    };

    const [textToVois, setTextToVois] = useState("");
    const [playState, setPlayState] = useState({});
    const [currentAudio, setCurrentAudio] = useState(null);
    const [abortController, setAbortController] = useState(null);
    const [audioFiles, setAudioFiles] = useState([]);
    const [audioIndex, setAudioIndex] = useState(1);

    const [correctAnswers, setCorrectAnswers] = useState([]);
    const [totalScore, setTotalScore] = useState(0);

    const calculateTotalScore = (correctAnswers, userAnswers, sectionBAnswers = []) => {
        let score = 0;

        correctAnswers.forEach((answer, index) => {
            if (answer.trim() == userAnswers[index]?.trim()) {
                score += 2;
            }
        });

        sectionBAnswers.forEach((answer) => {
            score += answer.score || 0;
        });

        return score;
    };

    useEffect(() => {
        if (isProblemsStarterMessage) {
            const extractedAnswers = messages[messages.length - 1]?.text
                ?.split("\n")
                ?.find((line) =>
                    line.startsWith("Correct answer") || // Inglizcha
                    line.startsWith("Правильные ответы") // Ruscha
                )
                ?.split(":")[1]
                ?.split(",")
                ?.map((answer) => answer.trim());

            if (extractedAnswers) {
                setCorrectAnswers(extractedAnswers);
                setTestScore(totalScore)
            }
        }
    }, [isProblemsStarterMessage, messages]);


    useEffect(() => {
        if (correctAnswers.length > 0 || sectionBScores.length > 0) {
            const total = calculateTotalScore(correctAnswers, myAnswersList, sectionBScores);
            setTotalScore(total);
        }
    }, [correctAnswers, myAnswersList, sectionBScores]);

    const [quizModal, setQuizModal] = useState(false);
    useEffect(() => {
        if (sectionBScores.length > 0) {
            setQuizModal(true);
        } else {
            setQuizModal(false);
        }
    }, [sectionBScores]);

    console.log("Total:", totalScore);

    useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.sender !== "user" && socket === "help") {
                setTextToVois(lastMessage.text);
            }
        }
    }, [messages]);
    const [playWait, setPLayWait] = useState()
    const playText = (index) => {
        axios.post("https://ttsapi.diyoraai.uz/tts", {
            text: textToVois
        }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "accept-language": courseLanguage,
            },
            responseType: "arraybuffer",
        }).then((response) => {
            const audioBlob = new Blob([response.data], { type: "audio/wav" });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);


            setCurrentAudio(audio);

            setAudioFiles(prev => [...prev, { index: audioIndex, url: audioUrl }]);
            setAudioIndex(prev => prev + 2);

            console.log("audio list", audioFiles)
        }).catch((err) => {
            if (axios.isCancel(err)) {
                console.log("Audio loading cancelled");
            } else {
                console.log(err);
            }
        });
    };

    console.log("udio blob",)

    const playSavedAudio = (index) => {
        const audioFile = audioFiles.find(file => file.index === index);

        if (audioFile && audioFile.url) {
            console.log("Playing audio:", audioFile.url);
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
            const audio = new Audio(audioFile.url);
            setCurrentAudio(audio);
            audio.play()
                .then(() => {
                    console.log("Audio started playing");
                    setPlayState({ [index]: { isPlayVois: true } });
                })
                .catch(err => {
                    console.error("Audio playback error:", err);
                });

            audio.onended = () => {
                setCurrentAudio(null);
                setPlayState({ [index]: { isPlayVois: false } });
            };
        } else {
            console.log("Audio file not found for index:", index);
        }
    };


    useEffect(() => {
        if (typeof textToVois === "string" && textToVois.trim() !== "") {
            playText(0);
        }
    }, [textToVois]);

    // useEffect(() => {
    //     if (lastMessageIdentifier?.text) {
    //         console.log("Oxirgi xabar:", lastMessageIdentifier.text);
    //     }
    // }, [lastMessageIdentifier]);
    const { enrollmentId, chapterId } = useParams();

    const first20Tests = tests.slice(1, 21)
    // .map(test => test.split("?")[0] + "?");
    console.log("testst", tests);
    // console.log("my answer", myAnswersList)

    const parsedTests = first20Tests.map((test, index) => {
        let questionMatch = test.match(/\[(.*?)\]/);
        let question = questionMatch ? questionMatch[1] : null;

        if (!question) {
            questionMatch = test.match(/^(.*?)\s[a-d]\)/);
            question = questionMatch ? questionMatch[1].trim() : "No question found";
        }

        const optionsMatch = test.match(/a\)(.*)/);
        const options = optionsMatch ? optionsMatch[1].split(/\s[bcd]\)/).map(opt => opt.trim()) : [];

        const userOption = myAnswersList[index];
        const correctOption = correctAnswers[index];
        const answerIndex = userOption ? userOption.charCodeAt(0) - 97 : -1;
        const answer = answerIndex >= 0 && answerIndex < options.length ? options[answerIndex] : "";

        const is_correct = userOption === correctOption;

        return {
            order: index + 1,
            question: question,
            options: options.map((opt, i) => `${String.fromCharCode(97 + i)}) ${opt}`),
            answer: answer ? `${userOption}) ${answer}` : "",
            is_correct: is_correct
        };
    });

    // console.log("bu map bolgan",parsedTests);
    const questionsJson = JSON.stringify(parsedTests, null, 4);
    const sectionBTotal = sectionBScores[0]?.score;

    const examData = {
        learner_id: enrollmentId,
        chapter_id: chapterId,
        total_score: totalScore,
        passed: totalScore >= 60,
        test: {
            overal: testScore,
            feedback: feedbackMessages[0],
            items: [
                parsedTests
            ]
        },
        section_b: {
            overal: sectionBTotal,
            feedback: "Needs improvement in problem-solving",
            tasks: [
                {
                    problem: sectionBMessages[1]?.text,
                    answer: "",
                    score: 10
                }
            ]
        }
    };

    console.log("examData", examData);
    const submitExamResults = () => {
        const examData = {
            learner_id: enrollmentId,
            chapter_id: chapterId,
            total_score: totalScore,
            passed: totalScore >= 60,
            test: {
                overal: testScore,
                feedback: feedbackMessages[0],
                items: [
                    questionsJson
                ]
            },
            section_b: {
                overal: sectionBTotal,
                feedback: "",
                tasks: [
                    {
                        problem: sectionBMessages[1]?.text,
                        answer: "x = 5",
                        score: 10
                    }
                ]
            }
        };
    }

    return (
        <div className="chat-container" style={{ width: "100% !important" }}>
            <div className="chat-header">
                <img src={Robot} alt="robot" />
                <div className="chat-name-block">
                    <h3 className="chat-name">
                        <span className="blue-text">{t('chatName')}</span>
                    </h3>
                    <p className="online-text">
                        {t('status')} {connectionStatus}
                        {!isConnectionOpen && (
                            <button
                                className="reconnect-button"
                                onClick={reconnectWebSocket}
                                disabled={readyState === ReadyState.CONNECTING}
                            >
                                {t('reconnect')}
                            </button>
                        )}
                    </p>
                </div>
                {socket === "help" && (
                    <h4 className="help-title">{t('helpTitle')}</h4>
                )}
            </div>
            <div className="chat-body" ref={chatBodyRef}>
                <div className="date-container">
                    <p className="date">{formatDate()}</p>
                </div>
                {messages.map((msg, index) => {
                    const lastMessageIndex = messages.length - 1;
                    const isAudioAvailable = audioFiles.some(audio => audio.index === lastMessageIndex);

                    return msg?.visible ? (
                        <div key={index} className={`message ${msg.sender === "user" ? "user-message" : ""}`}>
                            {msg.sender === "user" ? (
                                <TextRenderer text={msg.text} className="input-mes" />
                            ) : (
                                <TextRenderer text={msg.text} className="message-bot">
                                    <div className="vois-play">
                                        {isAudioAvailable || index !== lastMessageIndex ? (
                                            socket === "help" && (
                                                !playState[index]?.isPlayVois ? (
                                                    <i
                                                        className="ri-volume-up-fill"
                                                        onClick={() => playSavedAudio(index)}
                                                    ></i>
                                                )
                                                    : (
                                                        <img className="play-gif" src={PlayAudio} />
                                                    )
                                            )
                                        ) : null}
                                    </div>

                                </TextRenderer>
                            )}
                            <p className={`time ${msg.sender === "user" ? "time-user" : ""}`}>
                                {msg.time}
                            </p>
                        </div>
                    ) : null
                })}
                {isTyping && (
                    <div className="message typing-indicator">
                        <p className="message-bot">{t('botTyping')}</p>
                    </div>
                )}
            </div>
            <div className="chat-input">
                {(isTestMode || isProblemsStarterMessage) ? (
                    <button disabled={clicked} className="start-test-button" onClick={handleStartTest}>
                        {clicked ? t('starting') : t('startTest')}
                    </button>
                ) : (
                    <>
                        {isSectionB ? (
                            <textarea
                                rows={4}
                                placeholder="Your message"
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value);
                                }}
                                onKeyDown={(e) => e.key === "Enter" && handleSend()}
                                disabled={!isConnectionOpen}
                            />
                        ) : (
                            socket === "nazorat_test" ? (
                                <input
                                    type="text"
                                    placeholder="Your message"
                                    value={input}
                                    onChange={(e) => {
                                        if (isConnectionOpen) {
                                            // Faqat a, b, c, d harflariga ruxsat berish
                                            const filteredValue = e.target.value.replace(/[^abcd]/gi, "");
                                            setInput(filteredValue);
                                        }
                                    }}
                                    onKeyDown={(e) => e.key === "Enter" && handleSend()}
                                    disabled={!isConnectionOpen}
                                />
                            ) : (
                                <input
                                    type="text"
                                    placeholder="Your message"
                                    value={input}
                                    onChange={(e) => {
                                        setInput(e.target.value)

                                    }}
                                    onKeyDown={(e) => e.key === "Enter" && handleSend()}
                                    disabled={!isConnectionOpen}
                                />
                            )
                        )}
                        {socket === "help" && (
                            !isRecording ? (
                                <i onClick={startRecording} class="ri-mic-fill bot-microphone"></i>
                            ) : (<i onClick={handleStopAndSend}
                                class="ri-stop-circle-fill stop-recording record-border"></i>)
                        )}
                        <button
                            className="send-button"
                            onClick={handleSend}
                            disabled={!isConnectionOpen}
                        >
                            <span className="sendText">{t('sendBtn')}</span> <img src={SendImg} alt="Send" />
                        </button>
                    </>
                )}
            </div>
            {/* {quizModal && (<QuizModal score={totalScore} />)} */}
        </div>
    );
};

export default Chat;

