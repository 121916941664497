import {Route, Routes, useNavigate} from 'react-router-dom';
import './App.css';
import Background from './Components/Background/Background';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import Courses from './Pages/Courses/Courses'
import ListCourses from './Pages/ListCourses/ListCourses';
import ScrollToTop from './Components/ScrollTop/ScrollTop';
import Quiz from './Pages/Quiz/Quiz';
import Certificate from './Pages/Certificate/Certificate';
import Partners from './Pages/Partners/Partners';
import Test from './Pages/Test';
import CourseDetail from './Pages/Detail';
import AboutUs from './Pages/Partners/Partners';
import PartnersInfo from './Components/PartnersInfo/PartnersInfo';
import Payment from './Components/Payment';
import PaymentCredentials from './Components/PaymentCredentials';
import LayoutA from './Components/StudentDashboard';
import StudendDashboard from './Pages/StudenDashboard';
import StudendDashboardInfo from './Pages/StudenDashboard/Info';
import StudendDashboardCourses from './Pages/StudenDashboard/Courses';
import StudendDashboardCertificate from './Pages/StudenDashboard/Certificates';
import StudendDashboardPayment from './Pages/StudenDashboard/PaymentHistory';
import EnrollMentDetail from './Pages/StudenDashboard/Courses/EnrollmentDetail';
import {ToastContainer} from 'react-toastify';
import StudentDashboardCourseDetail from "./Pages/StudenDashboard/Courses/Lesson";
import StudentDashboardQuiz from "./Pages/StudenDashboard/Quiz";
import { useEffect, useState } from 'react';
import OpenPdf from './Components/OpenPdf/OpenPdf';
import News from './Components/News/News';

// import Layout from './Components/StudentDashboard/layout';

function App() {
    const [token, setToken] = useState(localStorage.getItem("token") || "");
    const navigate = useNavigate();
    useEffect(() => {
        const checkToken = () => {
            const storedToken = localStorage.getItem("token");
            if (!storedToken || storedToken === "undefined" || storedToken === "null") {
                localStorage.removeItem("token");
                setToken(""); 
                navigate("/");
                window.location.reload()
            }
        };
        window.addEventListener("storage", checkToken);
        return () => {
            window.removeEventListener("storage", checkToken);
        };
    }, [navigate]);

    return (
        <div className="App">
            <Background/>
            <Navbar/>
            <ScrollToTop/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/courses' element={<ListCourses/>}/>
                <Route path='/courses/:courseId' element={<Courses/>}/>
                <Route path='/certificate' element={<Certificate/>}/>
                <Route path='/quiz' element={<Quiz/>}/>
                <Route path="/quiz/test" element={<Test/>}/>
                <Route path='/partners' element={<PartnersInfo/>}/>
                <Route path='/about' element={<AboutUs/>}/>
                <Route path='/payment' element={<Payment/>}/>
                <Route path='/payment/credentials' element={<PaymentCredentials/>}/>
                <Route path='/news/:id'  element={<News />   }/>

                {/* Student Dashboard */}
                <Route path='/student/info' element={<StudendDashboardInfo/>}/>
                <Route path='/student/courses' element={<StudendDashboardCourses/>}/>
                <Route path='/student/courses/:enrollmentId' element={<EnrollMentDetail/>}/>
                <Route path='/student/certificate' element={<StudendDashboardCertificate/>}/>
                <Route path='/student/payment-history' element={<StudendDashboardPayment/>}/>
                <Route path='/student/courses/:enrollmentId/:chapterId/:order'
                       element={<StudentDashboardCourseDetail/>}/>
                <Route path='/student/courses/quiz/:enrollmentId/:chapterId'
                       element={<StudentDashboardQuiz/>}/>
                <Route path='/student/courses/quiz/:enrollmentId/:chapterId/view-pdf' element={<OpenPdf />} />

            </Routes>
            <Footer/>
            <ToastContainer/>
        </div>
    );
}

export default App;
