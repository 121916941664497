import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HeadImg from "../../images/bank.svg";
import ListCourses from "../ListCourses/ListCourses";
import Sell from "../../images/sell.png";
import Slider from "../../Components/Swiper/Slider";
import Image1 from "../../images/image1.png";
import Image2 from "../../images/image2.png";
import Image3 from "../../images/image3.png";
import ToLeftImg from "../../images/Arrow 1.svg";
import "./Home.css";

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation()

  const slides = [1, 2, 3];

  const certtificateImages = [
    {
      img: Image1,
      id: 1,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      desc: "International Institute of Finance and Accounting IIFA - a leading provider of professional qualifications in accounting, finance, management lorem",
    },
    {
      img: Image3,
      id: 2,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      desc: "IIFA - draws on an extensive network of professional leaders and experts to provide quality input into the practice of finance, accounting and auditing.",
    },
    {
      img: Image2,
      id: 3,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      desc: "We provide timely, effective and cost-effective training solutions that meet both organizational and individual requirements. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  return (
    <div>
      <div className="">
        <div className="header">
          <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide className="swiperBlock">
              <div className="container swiperContainer">
                <h1 className="header-title space_grotesk">
                  {t('headerTitle')}
                  <span className="yellow-text">{t('yellowText')}</span>
                </h1>
                <p className="header-desc">
                  {t('headerDesc')}
                </p>
              </div>

            </SwiperSlide>

            <SwiperSlide className="swiperBlock2">
              <div className="container swiperContainer swiper2Container">
                <h1 className="header-title">{t('headerTitle2')}</h1>
                <p className="header-desc">{t('headerDesc2')}</p>
              </div>
            </SwiperSlide>

          </Swiper>

          {/* <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
            style={{ width: "100%", height: "100vh" }} // 100% width va height berish
          >
            {[...Array(9)].map((_, index) => (
              <SwiperSlide key={index} style={{ width: "100%", height: "100%" }}>
                Slide {index + 1}
              </SwiperSlide>
            ))}
          </Swiper> */}
        </div>

        <div className="container">
          <div className="aboutPlatformDesc">
            <p className="aboutPlatformText">{t('aboutPlatformText')}</p>
            <p className="aboutPlatformTitle">{t('aboutPlatformTitle')}</p>
          </div>
        </div>
      </div>
      {/* <div className="knowledge platform">
        <div className="knowledge-top">
          <div className="swiper-block container">
            <Swiper
              onSwiper={setSwiperRef}
              onActiveIndexChange={(swiper) =>
                setActiveIndex(swiper.activeIndex)
              }
              slidesPerView={2.6}
              centeredSlides={true}
              initialSlide={2}
              spaceBetween={0}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
            >
              {certtificateImages.map((image, index) => (
                <SwiperSlide
                  key={index}
                  className={`swiper-slide swiper-slider swiper-card ${activeIndex === index ? "active" : ""
                    }`}
                >
                  <img
                    className="swipers-image"
                    src={image.img}
                    alt={`certificate ${index + 1}`}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="slide-desc">{image.desc}</p>
                  {/* <div className="to-right">
                    <img className="swiper-to-right" src={ToLeftImg} alt="" />
                  </div> */}
      {/* </SwiperSlide> */}
      {/* ))} */}
      {/* </Swiper> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      <ListCourses />
      <h3 className="courses-title">{t('CertificatesName')}</h3>

      <div className="knowledge container">
        <div className="knowledge-block knowledge-block-bot">
          <div className="knowledge-back"></div>
          <p className="knowledge-desc">
            {t('knowledgeDesc')}
          </p>
          <h2 className="knowledge-title space_grotesk ">
            {t('knowledgeTitle')}
          </h2>
        </div>
        <div className="knowledge-right">
          <Slider />
        </div>
      </div>
      {/* <div className="adven">
        <div className="container">
          <div className="adventages">
            <div className="adventages-left">
              <button className="adventages-btn space_grotesk">
                {t('adventagesBtn')}
              </button>
              <h2 className="adventages-title space_grotesk">
                {t('adventagesTitle')}
                <span className="yellow-text">{t('adventagesYellow')}</span>
              </h2>
              <Link to={"/courses"}>
                <button className="button btn more-btn">{t('moreBtn')}</button>
              </Link>
            </div>
            <div className="adventages-right">
              <img className="sell-img" src={Sell} alt="img" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="news container">
        {certtificateImages.map((image, index) => (
          <div className="news-block" key={index}>
            <div className="news-img-line">
              <img className="news-img" src={image.img} alt="news" />
            </div>
            <a onClick={() => navigate(`/news/${image?.id}`, { state: image })} className="news-title">{image.title}</a>
            <p className="news-text">{image.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
