export const lessons = [
  {
    num: 1,
    title: "Audit",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac a, porttitor ac arcu morbi bibendum interdum non.",
  },
  {
    num: 2,
    title: "Audit",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac a, porttitor ac arcu morbi bibendum interdum non.",
  },
  {
    num: 3,
    title: "Audit",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac a, porttitor ac arcu morbi bibendum interdum non.",
  },
];

export const courses = [
  { id: 1, name: "Audit", author: "Azizov Aziz", lessonNumber: "2-dars" },
  { id: 2, name: "Audit", author: "Azizov Aziz", lessonNumber: "3-dars" },
  { id: 3, name: "Audit", author: "Azizov Aziz", lessonNumber: "4-dars" },
  { id: 4, name: "Audit", author: "Azizov Aziz", lessonNumber: "5-dars" },
  { id: 5, name: "Audit", author: "Azizov Aziz", lessonNumber: "6-dars" },
  { id: 6, name: "Audit", author: "Azizov Aziz", lessonNumber: "7-dars" },
  { id: 7, name: "Audit", author: "Azizov Aziz", lessonNumber: "8-dars" },
  { id: 8, name: "Audit", author: "Azizov Aziz", lessonNumber: "9-dars" },
  { id: 8, name: "Audit", author: "Azizov Aziz", lessonNumber: "10-dars" },
  { id: 8, name: "Audit", author: "Azizov Aziz", lessonNumber: "11-dars" },
  { id: 8, name: "Audit", author: "Azizov Aziz", lessonNumber: "12-dars" },
  { id: 8, name: "Audit", author: "Azizov Aziz", lessonNumber: "13-dars" },
  { id: 8, name: "Audit", author: "Azizov Aziz", lessonNumber: "14-dars" },
];
