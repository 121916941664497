import React from "react";
import Logo from "../../images/logo.svg";
import { NavLink, useLocation } from "react-router-dom";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const location = useLocation()
  const {t} = useTranslation()
  if (location.pathname.startsWith('/student')) {
    return null; // Hide Navbar on Student Dashboard
  }
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-block">
          <div className="footer-logo">
            <a className="logo-img" href="#">
              <img src={Logo} alt="Logo" />
            </a>
            <h4 className="footer-title">{t('footerTitle')}</h4>
          </div>
          <div className="footer-top">
            <div className="footer-left">
              <a href="tel:+176355471297" className="footer-link">
                +1 (7635) 547-12-97
              </a>
              <a href="mailto:iifa@edu.com" className="footer-link">
                iifa@edu.com
              </a>
            </div>
            <div className="footer-right">
              <NavLink to={"/about"} className="footer-link">
                {t('about')}
              </NavLink>
              <NavLink to={"/partners"} className="footer-link">
                {t('partners')}
              </NavLink>
              <NavLink to={"/courses"} className="footer-link">
                {t('courses')}
              </NavLink>
            </div>
          </div>
          <div className="footer-bottom">
            <p className="footer-in">© 2024 • All Rights Reserved</p>
            <div className="addres-block">
              <a href="https://facebook.com" className="addres-link" target="_blank" rel="noopener noreferrer">
                <i className="ri-facebook-fill"></i>
              </a>
              <a href="https://instagram.com" className="addres-link" target="_blank" rel="noopener noreferrer">
                <i className="ri-instagram-fill"></i>
              </a>
              <a href="https://telegram.org" className="addres-link" target="_blank" rel="noopener noreferrer">
                <i className="ri-telegram-fill"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;