import React, { useEffect, useState } from 'react';
import PartnersImg from '../../images/partners4.png'
import PartnersImg2 from '../../images/partners5.png'
import PartnersImg3 from '../../images/partners6.png'
import Sell from "../../images/sell.png";
import "./PartnersInfo.css"
import { API } from '../../constants';
import axios from 'axios';
import api from '../RefreshToken/RefreshToken';
import { useTranslation } from 'react-i18next';

const PartnersInfo = () => {
    const [partners, setPartners] = useState()
    const lang = localStorage.getItem('i18nextLng')
    const {t} = useTranslation()

    useEffect(() => {
        api.get(`${API}/management/partners`,{
            headers: { 'Accept-Language': lang }
        }).then((res) => {
            console.log(res.data)
            setPartners(res.data)
        }).catch((err) => {
            console.log(err)
        })
    })
    return (
        <>
            <div className='container'>
                <h2 className="partners-title partners-info-title">{t('partnersTitle')}</h2>
                {partners?.map((item) => (
                    <div className="partners-block">
                    <div className="partners-left partners-info-left">
                        <h5 className="partners-left-title left-title partners-info-subtitle">{item?.title}</h5>
                        <p className="partners-desc">{item?.description}</p>
                    </div>
                    <div className="partners-right partners-info-img">
                        <img src={`${API}/${item?.photo}`} alt="image" className="partners-img" />
                    </div>
                </div>
                ))}
            </div>

        </>
    );
}

export default PartnersInfo;
