import React, { useState } from 'react'
import ClickIcon from "../../images/click.svg"
import VisaIcon from "../../images/visaIcon.svg"
import PaymeIcon from "../../images/payme.svg"
import "./styles.css"
import { useNavigate } from 'react-router-dom'

const Payment = () => {

    const [selectedMethod, setSelectedMethod] = useState('payme');
    const navigate = useNavigate()

    const paymentMethods = [
        { id: 'payme', name: 'Payme', logo: PaymeIcon },
        { id: 'click', name: 'Click', logo: ClickIcon },
        { id: 'visa', name: 'Visa', logo: VisaIcon }
    ];

    const handleChange = (methodId) => {
        setSelectedMethod(methodId);
    };

    return (
        <div className='container payment-container'>
            <h3 className='payment-title'>Payment</h3>
            <div className="payment-wrapper">
                <div className="paymanet-content">
                    <p>Course</p>
                    <p className='content-value'>Audit - Azizov Aziz</p>
                </div>
                <div className="paymanet-content">
                    <p>Price</p>
                    <p className='course-price'>250 000 UZS</p>
                </div>
                <div className="paymanet-content">
                    <p>Date</p>
                    <p className='content-value'>02.02.2024</p>
                </div>
            </div>
            <div className="payment-methods">
                {paymentMethods.map((method) => (
                    <label
                        key={method.id}
                        className={`${selectedMethod === method.id ? "active payment-option" : "payment-option"}`}
                    >
                        <div className="radio-container">
                            <input
                                type="radio"
                                className="radio-input"
                                name="payment"
                                checked={selectedMethod === method.id}
                                onChange={() => handleChange(method.id)}
                            />
                            <span className="payment-name">{method.name}</span>
                        </div>
                        <img src={method.logo} alt="" />
                    </label>
                ))}
            </div>
            <div className="payment-btns-wrapper">
                <button className="appeal-cancel">
                    Cancel
                </button>
                <button
                    type="submit"
                    className="appeal-confirm"
                    onClick={() => navigate('/payment/credentials')}>
                    Confirm
                </button>
            </div>
        </div>
    )
}

export default Payment