import React, { useEffect, useState } from 'react'
import './studentInfo.css'
import PhoneNumberInput from '../../Inputs/PhoneNumberInput';
import axios from 'axios';
import { API } from '../../../constants';
import api from '../../RefreshToken/RefreshToken';
import { useTranslation } from 'react-i18next';
import Plus from '../../../images/plus.svg'
import { toast } from 'react-toastify';

export default function InfoComponent() {
  const [countryCode, setCountryCode] = useState("+998");
  const TOKEN = localStorage.getItem("token")
  const [myProfile, setMyProfile] = useState()
  const [phoneNumber, setPhoneNumber] = useState("");
  const { t } = useTranslation();
  const [user, setUser] = useState({})
  const [educationDaerge, setEducationDarge] = useState([])
  const [country, setCountry] = useState([])
  const lang = localStorage.getItem("i18nextLng")

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    fatherName: "",
    birthDate: "",
    gender: "",
    citizenship: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
    phone: "",
    email: "",
    education: "",
    examLanguage: "",
    identity_card: null,
    edu_doc: null,
    photo: null,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Form Data:", formData);
  //   // Bu yerda ma'lumotlarni backend'ga yuborish logikasini qo'shishingiz mumkin
  // }

  useEffect(() => {
    api.get(`${API}/profile`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`
      },
    }).then((res) => {
      console.log(res?.data);
      setMyProfile(res?.data)
      setPhoneNumber(res?.data?.phone_number)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    api
      .get(`${API}/profile`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setUser(response.data))
      .catch((error) => console.error("bu profile err", error));
  }, [])
  // console.log("user", user);

  useEffect(() => {
    api.get(`${API}/management/countries`, {
      headers: { "Accept-Language": lang }
    }).then((res) => {
      setCountry(res?.data);
      console.log("bu country", res?.data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    api.get(`${API}/management/edu-levels`, {

    })
      .then((res) => {
        console.log("bu education", res?.data);
        setEducationDarge(res?.data);
      }
      ).catch((err) => {
        console.log(err);
      });
  }, []); 

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.first_name || "",
        lastName: user.last_name || "",
        fatherName: user.middle_name || "",
        birthDate: user.birthdate || "",
        gender: user.gender || "",
        citizenship: user.citizenship || "",
        address: user.living_address || "",
        city: user.living_city || "",
        postalCode: user.postal_code || "",
        country: user.living_country || "",
        phone: user.phone_number || "",
        email: user.email || "",
        education: user.edu_level_id || "",
        examLanguage: user.exam_language || "",
      });
    }
  }, [user]);

  const updateProfile = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    if (formData.identity_card) {
      formDataToSend.append("identity_card", formData.identity_card);
    }
    if (formData.edu_doc) {
      formDataToSend.append("edu_doc", formData.edu_doc);
    }
    if (formData.photo) {
      formDataToSend.append("photo", formData.photo);
    }
    formDataToSend.append("birthdate", formData.birthDate);
    formDataToSend.append("citizenship", formData.citizenship);
    formDataToSend.append("edu_level_id", formData.education);
    formDataToSend.append("first_name", formData.name);
    formDataToSend.append("last_name", formData.lastName);
    formDataToSend.append("middle_name", formData.fatherName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("exam_language", formData.examLanguage);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("living_address", formData.address);
    formDataToSend.append("living_country", formData.country);
    formDataToSend.append("postal_code", formData.postalCode);
    formDataToSend.append("phone_number", `${countryCode}${phoneNumber}`);
    formDataToSend.append("living_city", formData.city);
    console.log("qoshilyotkan habarlar", formDataToSend)
    try {
      const response = await api.put(`${API}/profile/update`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error.response?.data || error);
    }
  };

  return (
    <div>
      {t("studentInfo")}
      <div className='info-wrapper'>
        <div className='info-wrapper'>
          <form autoComplete='off' onSubmit={updateProfile}>
            <div className='info-form-wrapper'>
              <label>{t("fullName")}</label>
              <input
                required
                name="name"
                className="modalLogin-input input profile-save"
                placeholder={t("fullNamePlaceholder")}
                type="text"
                value={formData.name}
                onChange={handleChange}
              />

              <label>{t("lastName")}</label>
              <input
                required
                name="lastName"
                className="modalLogin-input input profile-save"
                placeholder={t("lastNamePlaceholder")}
                type="text"
                value={formData.lastName}
                onChange={handleChange}
              />

              <label>{t("fatherName")}</label>
              <input
                required
                name="fatherName"
                className="modalLogin-input input profile-save"
                placeholder={t("fatherNamePlaceholder")}
                type="text"
                value={formData.fatherName}
                onChange={handleChange}
              />

              <label>{t("birthDate")}</label>
              <input
                required
                name="birthDate"
                className="modalLogin-input input profile-save"
                placeholder={t("birthDatePlaceholder")}
                type="date"
                value={formData.birthDate}
                onChange={handleChange}
              />

              <label>{t("gender")}</label>
              <select
                required
                name="gender"
                className="modalLogin-input input profile-save"
                value={formData.gender}
                onChange={handleChange}
              >
                <option hidden>{t("selectGender")}</option>
                <option value="M">{t("male")}</option>
                <option value="F">{t("female")}</option>
              </select>
              <div>{t('identityCard')}</div>
              <label className='file-info modalLogin-input input'>
                <img src={Plus} alt="" />
                <span style={{ marginLeft: "20px" }}>
                  {formData.identity_card
                    ? formData.identity_card.name.length > 25
                      ? formData.identity_card.name.substring(0, 35) + "..."
                      : formData.identity_card.name
                    : user.identity_card
                      ? user.identity_card.length > 25
                        ? user.identity_card.substring(0, 35) + "..."
                        : user.identity_card
                      : t("noFile")}
                </span>

                <input
                  style={{ display: "none" }}
                  name="identity_card"
                  className="modalLogin-input input profile-save"
                  placeholder={t("identityCardPlaceholder")}
                  type="file"
                  onChange={(e) => setFormData({ ...formData, identity_card: e.target.files[0] })}
                />
              </label>

              <div>{t('eduDoc')}</div>
              <label className='file-info modalLogin-input input'>
                <img src={Plus} alt="" />
                <span style={{ marginLeft: "20px" }}>
                  {formData.edu_doc
                    ? formData.edu_doc.name.length > 35
                      ? formData.edu_doc.name.substring(0, 35) + "..."
                      : formData.edu_doc.name
                    : user.edu_doc
                      ? user.edu_doc.length > 35
                        ? user.edu_doc.substring(0, 35) + "..."
                        : user.edu_doc
                      : t("noFile")}
                </span>

                <input
                  style={{ display: "none" }}
                  name="edu_doc"
                  className="modalLogin-input input profile-save"
                  placeholder={t("eduDocPlaceholder")}
                  type="file"
                  onChange={(e) => setFormData({ ...formData, edu_doc: e.target.files[0] })}
                />
              </label>

              <div>{t('photo')}</div>
              <label className='file-info modalLogin-input input'>
                <img src={Plus} alt="" />
                <span style={{ marginLeft: "20px" }}>
                  {formData.photo
                    ? formData.photo.name.length > 25
                      ? formData.photo.name.substring(0, 35) + "..."
                      : formData.photo.name
                    : user.photo
                      ? user.photo.length > 25
                        ? user.photo.substring(0, 35) + "..."
                        : user.photo
                      : t("noFile")}
                </span>

                <input
                  style={{ display: "none" }}
                  name="photo"
                  className="modalLogin-input input profile-save"
                  placeholder={t("photoPlaceholder")}
                  type="file"
                  onChange={(e) => setFormData({ ...formData, photo: e.target.files[0] })}
                />
              </label>


              <label>{t("citizenship")}</label>
              <select
                required
                name="citizenship"
                className="modalLogin-input input profile-save"
                value={formData.citizenship}
                onChange={handleChange}>
                <option hidden>{t("yourCitizenship")}</option>
                <option value="uzbek">{t("uzbek")}</option>
                <option value="english">{t("english")}</option>
                <option value="russian">{t("russian")}</option>
                <option value="ukrainian">{t("ukrainian")}</option>
              </select>

              <label>{t("address")}</label>
              <input
                required
                name="address"
                className="modalLogin-input input profile-save"
                placeholder={t("addressPlaceholder")}
                type="text"
                value={formData.address}
                onChange={handleChange}
              />

              <label>{t("city")}</label>
              <input
                required
                name="city"
                className="modalLogin-input input profile-save"
                placeholder={t("cityPlaceholder")}
                type="text"
                value={formData.city}
                onChange={handleChange}
              />

              <label>{t("postalCode")}</label>
              <input
                required
                name="postalCode"
                className="modalLogin-input input profile-save"
                placeholder={t("postalCodePlaceholder")}
                type="text"
                value={formData.postalCode}
                onChange={handleChange}
              />

              <label>{t("country")}</label>
              <select
                required
                name="country"
                className="modalLogin-input input profile-save"
                placeholder={t("countryPlaceholder")}
                type="text"
                value={formData.country}
                onChange={handleChange}
              >
                <option hidden>{t("selectCountry")}</option>
                {country?.map((item) => (
                  <option value={item?.code} key={item?.id}>{item?.name}</option>
                ))}
              </select>


              <label>{t("phone")}</label>
              <PhoneNumberInput
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />

              <label>{t("email")}</label>
              <input
                required
                name="email"
                className="modalLogin-input input profile-save"
                placeholder={t("emailPlaceholder")}
                type="email"
                value={formData.email}
                onChange={handleChange}
              />

              <label>{t("educationLevel")}</label>
              <select
                required
                name="education"
                className="modalLogin-input input profile-save"
                value={formData.education}
                onChange={handleChange}
              >
                <option hidden >{t("selectEducationLevel")}</option>
                {educationDaerge?.map((item) => (
                  <option value={item?.id}>{item?.title}</option>
                ))}
              </select>

              <label>{t("examLanguage")}</label>
              <select
                required
                name="examLanguage"
                className="modalLogin-input input profile-save"
                value={formData.examLanguage}
                onChange={handleChange}
              >
                <option value="">{t("selectExamLanguage")}</option>
                <option value="uz">{t("uzbek")}</option>
                <option value="ru">{t("russian")}</option>
                <option value="en">{t("english")}</option>
                <option value="uk">{t("ukrainian")}</option>
              </select>
            </div>

            <div className='info-form-button'>
              <button className="logout profile-save">
                {t("saveBtn")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div >
  )
}

