import axios from "axios";
import { API,  } from "../../constants";
import { useNavigate } from "react-router-dom";

const api = axios.create({
    baseURL: API,
});


api.interceptors.response.use(
    response => response,
    async (error) => {
        if (error.response && error.response.status === 401 && !error.config._retry) {
            error.config._retry = true; 

            try {
                const refreshResponse = await axios.post(`${API}/token/refresh`, {}, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("studentRefreshToken")}`,
                        "Content-Type": "application/json"
                    }
                });
                const newToken = refreshResponse.data.access_token;
                const newRefreshToken = refreshResponse.data.refresh_token
                localStorage.setItem("token", newToken);
                localStorage.setItem("studentRefreshToken", newRefreshToken)
                console.log("token yangilandi", refreshResponse?.data)

                if (!error.config.headers) {
                    error.config.headers = {};
                }
                error.config.headers["Authorization"] = `Bearer ${newToken}`;
                error.config.withCredentials = true;

                return axios(error.config);
            } catch (refreshError) {
                console.log("Refresh token ishlamadi:", refreshError);
                localStorage.removeItem("token");
                localStorage.removeItem("studentRefreshToken");
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);


export default api;
