import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from "../../../images/logo.svg";
import { useTranslation } from 'react-i18next';
import CloseImg from '../../../images/close-nav.png';

export default function Sidebar({ isSidebarOpen }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [activeNav, setActiveNav] = useState(false);

    return (
        <>
            < div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <div className="logo">
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <img src={Logo} alt="logo image" />
                    </Link>
                </div>
                <ul>
                    <Link to={'/student/info'} style={{ textDecoration: 'none' }}>
                        <li className={`menu-item  ${location.pathname === '/student/info' ? 'menu-active' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-grid" viewBox="0 0 16 16">
                                <path
                                    d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                            </svg>
                            {t('studentInfo')}
                        </li>
                    </Link>
                    <Link to={'/student/courses'} style={{ textDecoration: 'none' }}>
                        <li className={`menu-item  ${location.pathname === '/student/courses' ? 'menu-active' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-bar-chart" viewBox="0 0 16 16">
                                <path
                                    d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                            </svg>
                            {t('coursesTitle')}
                        </li>
                    </Link>
                    <Link to={'/student/certificate'} style={{ textDecoration: 'none' }}>
                        <li className={`menu-item  ${location.pathname === '/student/certificate' ? 'menu-active' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                <path
                                    d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                <path
                                    d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                            </svg>
                            {t('mySertificates')}
                        </li>
                    </Link>
                    <Link to={'/student/payment-history'} style={{ textDecoration: 'none' }}>
                        <li className={`menu-item  ${location.pathname === '/student/payment-history' ? 'menu-active' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-wallet" viewBox="0 0 16 16">
                                <path
                                    d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                            </svg>
                            {t('paymentInfo')}
                        </li>
                    </Link>
                </ul>
                <div className="bottom-section">
                    <div style={{ textAlign: 'center' }}>
                        <img src="/verf.png" width={'93px'} alt="logout" />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button onClick={() => {
                            if (window.confirm("Are you sure you want to logout?")) {
                                localStorage.clear();
                                navigate('/');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 400);
                            }
                        }} className="logout">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" style={{ color: 'white' }}
                                    d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z" />
                                <path fillRule="evenodd" style={{ color: 'white' }}
                                    d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z" />
                            </svg>
                            {t('logout')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="navbar student-navbar">
                <div onClick={() => setActiveNav(!activeNav)} className="toggle">
                    <span className="toggle-line"></span>
                    <span className="toggle-line2"></span>
                    <span className="toggle-line"></span>
                </div>
                <a href="/" className="logo-link">
                    <img src={Logo} alt="Logo" />
                </a>
                <ul className={`nav-list ${activeNav ? "nav-list--active" : ""}`}>
                    <div className="list-logo-block">
                        <a href="/" className="logo-link">
                            <img src={Logo} alt="Logo" />
                        </a>
                        <img className="close-btn" onClick={() => setActiveNav(false)} src={CloseImg} alt="Close" />
                    </div>
                    <li className="nav-list-item">
                        <Link to={'/student/info'} style={{ textDecoration: 'none' }}>
                            <li className={`menu-item  ${location.pathname === '/student/info' ? 'menu-active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-grid" viewBox="0 0 16 16">
                                    <path
                                        d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                                </svg>
                                {t('studentInfo')}
                            </li>
                        </Link>
                    </li>
                    <li className="nav-list-item">
                        <Link to={'/student/courses'} style={{ textDecoration: 'none' }}>
                            <li className={`menu-item  ${location.pathname === '/student/courses' ? 'menu-active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-bar-chart" viewBox="0 0 16 16">
                                    <path
                                        d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                                </svg>
                                {t('coursesTitle')}
                            </li>
                        </Link>
                    </li>
                    <li className="nav-list-item">
                        <Link to={'/student/certificate'} style={{ textDecoration: 'none' }}>
                            <li className={`menu-item  ${location.pathname === '/student/certificate' ? 'menu-active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                    <path
                                        d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                </svg>
                                {t('mySertificates')}
                            </li>
                        </Link>
                    </li>
                    <li className="nav-list-item">
                        <Link to={'/student/payment-history'} style={{ textDecoration: 'none' }}>
                            <li className={`menu-item  ${location.pathname === '/student/payment-history' ? 'menu-active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-wallet" viewBox="0 0 16 16">
                                    <path
                                        d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                                </svg>
                                {t('paymentInfo')}
                            </li>
                        </Link>
                    </li>
                     <div style={{ textAlign: 'center' }}>
                        <button onClick={() => {
                            if (window.confirm("Are you sure you want to logout?")) {
                                localStorage.clear();
                                navigate('/');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 400);
                            }
                        }} className="logout">
                            {t('logout')}
                        </button>
                    </div>
                </ul>
                <div className="sign-icon">
                    {/* <img onClick={() => setIsModalOpen(true)} src={SignIn} alt="Sign In" /> */}
                    <i class="ri-user-fill"></i>
                </div>
            </div >
        </>
    )
}
