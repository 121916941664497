import { useEffect, useState, useRef } from "react";
import * as pdfjsLib from "pdfjs-dist";
import workerSrc from "pdfjs-dist/build/pdf.worker?url";

const OpenPdf = () => {
    const [pdfUrl, setPdfUrl] = useState('');
    const containerRef = useRef(null);

    useEffect(() => {
        const storedPdfUrl = sessionStorage.getItem('pdfUrl');
        if (storedPdfUrl) {
            const fullUrl = `https://iifabackend.diyoraai.uz/${storedPdfUrl}`;
            console.log("Yuklangan PDF URL:", fullUrl);
            setPdfUrl(fullUrl);
        }
    }, []);

    useEffect(() => {
        if (!pdfUrl) return;

        const loadPdf = async () => {
            try {
                pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

                const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
                if (!pdf) {
                    console.error("PDF yuklanmadi!");
                    return;
                }
                console.log("PDF sahifalar soni:", pdf.numPages);
                if (containerRef.current) {
                    containerRef.current.innerHTML = "";
                }
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: 1.5 });
                    const canvas = document.createElement("canvas");
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    const context = canvas.getContext("2d");

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };
                    await page.render(renderContext).promise;
                    containerRef.current.appendChild(canvas);
                }
            } catch (error) {
                console.error("PDF yuklashda xatolik:", error);
            }
        };
        loadPdf();
    }, [pdfUrl]);
    return (
        <div
            className="pdf-component"
            ref={containerRef}
            onContextMenu={(e) => e.preventDefault()}
        ></div>
    );
};

export default OpenPdf;
