import React, { useState } from 'react'
import "./styles.css"

const PaymentCredentials = () => {

    const [formData, setFormData] = useState({
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        isDefault: true,
        saveCard: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const formatCardNumber = (value) => {
        return value
            .replace(/\s/g, '')
            .replace(/(\d{4})/g, '$1 ')
            .trim();
    };

    return (
        <div className='container'>
            <div className="card-form">
                <h2 className="form-header">Payment</h2>
                <p className="form-subtitle">Add credit or debit card</p>
                <p className="form-info">Your payment details are stored securely.<br />By adding a card, you won't be charged yet.</p>
                <div className="form-group">
                    <label className="form-label">Card number</label>
                    <input
                        type="text"
                        className="form-input"
                        name="cardNumber"
                        placeholder="0000 0000 0000 0000"
                        value={formData.cardNumber}
                        onChange={(e) => {
                            const formatted = formatCardNumber(e.target.value);
                            setFormData(prev => ({ ...prev, cardNumber: formatted }));
                        }}
                        maxLength="19"
                    />
                </div>
                <div className="expiry-cvv-container">
                    <div className="form-group">
                        <label className="form-label">Expire date</label>
                        <input
                            type="text"
                            className="form-input"
                            name="expiryDate"
                            placeholder="MM / YYYY"
                            value={formData.expiryDate}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">CVV</label>
                        <input
                            type="text"
                            className="form-input"
                            name="cvv"
                            placeholder="123"
                            value={formData.cvv}
                            onChange={handleChange}
                            maxLength="3"
                        />
                    </div>
                </div>
                <div className="toggle-container">
                    <span className="toggle-label">Make this my default card</span>
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            name="isDefault"
                            checked={formData.isDefault}
                            onChange={handleChange}
                        />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                <div className="toggle-container">
                    <span className="toggle-label">Save this card for next time</span>
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            name="saveCard"
                            checked={formData.saveCard}
                            onChange={handleChange}
                        />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default PaymentCredentials;