import React, { useEffect, useState } from 'react'
import Layout from '../../../Components/StudentDashboard/Layout'
import CourseProgress from '../../../Components/StudentDashboard/Courses'
import axios from 'axios'
import { API } from '../../../constants'
import api from '../../../Components/RefreshToken/RefreshToken'

export default function StudendDashboardCourses() {
    const [enroll, setEnroll] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!!localStorage.getItem("token")) {
            api.get(`${API}/student/learning/enrollments`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            }).then((response) => {
                console.log(response);
                setEnroll(response.data);
                setLoading(false);
            })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        }
    }, []);

    console.log("dash", enroll)

    return (
        <Layout>
            {loading && <p style={{ textAlign: "center" }}>Loading...</p>}
            {(enroll?.length === 0 && !loading) && <p style={{ textAlign: "center" }}>No enrollments</p>}
            {
                enroll?.map(item => {
                    console.log(item?.course?.language);
                    return (
                        <CourseProgress
                            key={item.id}
                            id={item.id}
                            image={`${API}/${item.course.photo}`}
                            title={item.course.title}
                            authorFirstName={item.course.mentor.first_name}
                            authorLastName={item.course.mentor.last_name}
                            progress={item?.progress}
                            countOfLessons={item.course.count_of_lessons}
                            courseLanguage={item?.course?.language}
                        />
                    )
                })
            }
        </Layout>
    )
}
