import React from 'react'
import "./styles.css"
import { useParams } from 'react-router-dom';
import { API } from '../../../../constants';

const Contents = ({ title = "", lessons }) => {
    const { courseId } = useParams();
    const [cChapter, setChapter] = React.useState(null);

    function handlePlayClick(id) {
        console.log(id, courseId, 1)
    }

    const handleClickChapter = (lesson) => {
        setChapter((prev) => prev?.id !== lesson.id ? lesson : null);
    };

    return (
        <div>
            <h3 className='content-title'>{title}</h3>
            <div className='main-content'>
                {lessons?.length === 0 && <p>No Chapters yet</p>}
                {lessons?.map((chapter, index) => {
                    return (
                        <div onClick={() => handleClickChapter(chapter)} key={index} className='content-text'>
                            <div>
                                <p className="number-static">{index + 1}</p>
                                <p className='lesson-title'>{chapter.title}</p>
                            </div>
                            {cChapter?.id === chapter?.id && (
                                <div className='chapter-content-l'>
                                    {
                                        chapter?.lessons?.length === 0 ? (
                                            <p style={{ textAlign: "center" }}>No Lessons yet</p>
                                        ) : chapter?.lessons?.slice().sort((a, b) => a.order - b.order)
                                        .map((lesson, indexx) => {
                                            return (
                                                <div key={lesson?.order} className='lesson-content-chapter'>
                                                    <div className="lesson-content-chapter-m">
                                                        <div className="width-chapter-img">
                                                            <img className="chapter-lesson-photo-l"
                                                                src={`${API}/${lesson?.photo?.replace('http://127.0.0.1:8000/', 'http://192.168.100.112:8001/').replace('0//', '/').replace('1//', '/')}`}
                                                                alt={lesson?.title} />
                                                        </div>
                                                        <div className="lesson-content-chapter-m-text">
                                                            <p className="lesson-title-c">{lesson.title}</p>
                                                            <p className="lesson-title-m line-descr" dangerouslySetInnerHTML={{ __html: lesson.description }}></p>
                                                        </div>
                                                    </div>
                                                    {/*<div className="lesson-content-chapter-m-file">*/}
                                                    {/*    <a href="">*/}
                                                    {/*        File*/}
                                                    {/*    </a>*/}
                                                    {/*</div>*/}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Contents;
