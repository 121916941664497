import React from 'react'
import Layout from '../../../Components/StudentDashboard/Layout'
import InfoComponent from '../../../Components/StudentDashboard/Info'

export default function StudendDashboardInfo() {
    return (
        <Layout>
           <InfoComponent />
        </Layout>
    )
}
