import React from "react";
import Sidebar from "./Sidebar";

const LayoutA = () => {
    return (
        <div>
            <Sidebar />
        </div>
    )
}

export default LayoutA;