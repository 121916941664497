import { useLocation, useNavigate, useParams, useRoutes } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { courses } from "../../../Courses/data";
import axios from "axios";
import { API } from "../../../../constants";
import html2pdf from "html2pdf.js";
import ScrollToTop from "../../../../Components/ScrollTop/ScrollTop";
import { motion } from "framer-motion";
import Chat from "../../../../Components/Chat";
import BookImg from "../../../../images/BookImg.png";
import Lock from "../../../../images/lock.svg";
import Layout from "../../../../Components/StudentDashboard/Layout";
import { toast } from "react-toastify";
import api from "../../../../Components/RefreshToken/RefreshToken";
import * as pdfjsLib from "pdfjs-dist";
import ComplaintModal from "../../../../Components/ComplaintModal/ComplaintModal";
import WaitVideo from '../../../../images/Animation2.gif'
import { useTranslation } from "react-i18next";


const StudentDashboardCourseDetail = (props) => {
    const location = useLocation();
    const { courseId, enrollmentId, chapterId, order } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentCourse, setCurrentCourse] = useState(null);
    const coursesPerPage = 8;
    const [currentVideoIndex, setCurrentVideoIndex] = useState(order - 1);
    const navigate = useNavigate();
    const [complaintModal, setComplaintModal] = useState(false)
    const [videos, setVideos] = useState([]);
    const [complaintTitle, setComplaintTitle] = useState("")
    const [complaintDesc, setComplaintDesc] = useState("")
    const TOKEN = localStorage.getItem("token")
    const {t} = useTranslation()

    useEffect(() => {
        api.get(`${API}/student/learning/lessons/?chapter_id=${chapterId}&lesson_order=${order}&enrollment_id=${enrollmentId}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
            .then((response) => {
                setCurrentCourse(response.data);
                setVideos(() => response.data.lessons?.map((lsn) => lsn.order === response.data?.current_lesson?.order ? { ...response.data?.current_lesson } : lsn));
            }).catch((error) => {
                console.log(error, "error");

                // window.location.reload();
                navigate("/")
            })
    }, [location?.pathname]);


    // useEffect(() => {
    //     axios.get(`/courses/chapters/${chapterId}/exam-data`, {
    //         headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
    //     }).then(response => {
    //         console.log("response", response);
    //     }).catch((error) => {
    //         toast.error(error);
    //     })
    // }, [chapterId]);

    const videoVariants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
    };

    const courseLanguage = currentCourse?.course_language

    const handleNext = () => {
        if (currentVideoIndex < videos.length - 1) {
            setCurrentVideoIndex(currentVideoIndex + 1);
            navigate(`/student/courses/${enrollmentId}/${chapterId}/${+order + 1}`);
        } else {
            navigate(`/student/courses/quiz/${enrollmentId}/${chapterId}`, {
                state: { courseLanguage }
            });
        }
    };

    const textContent = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac a, porttitor ac arcu morbi bibendum interdum non. Nisi etiam posuere orci lacus neque.
  `;

    const handleDownloadPdf = () => {
        html2pdf().from(textContent).save("Audit.pdf");
    };

    const currentCourses = courses.slice(
        (currentPage - 1) * coursesPerPage,
        currentPage * coursesPerPage
    );
    const videoUrl = videos[currentVideoIndex]?.video_url;
    const handleViewPdf = () => {
        const pdfUrl = videos[currentVideoIndex]?.pdf_url;
        if (pdfUrl) {
            sessionStorage.setItem('pdfUrl', pdfUrl);
            window.open(`/student/courses/quiz/${enrollmentId}/${chapterId}/view-pdf`, '_blank');
        }
    };

    const handleOpenComplaint = () => {
        setComplaintModal(x => !x)
    }
    const submitComplaint = (e) => {
        e.preventDefault()
        api.post(`/student/complaint`, {
            title: complaintTitle,
            description: complaintDesc,
            course_id: currentCourse?.course_id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
        }).then((res) => {
            setComplaintModal(false)
            toast.success("The complaint was successfully submitted.")

        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <Layout>
            <div className="lesson container">
                <ScrollToTop />
                <div className="video-block">
                    <div className="lesson-block" style={{ padding: "0" }}>
                        <div className="lesson-name video-name">
                            <h2 className="course-name video-title">
                                {videos[currentVideoIndex]?.title}
                            </h2>
                            <p className="course-author"
                                style={{ marginBottom: "5px !important" }}>{currentCourse?.mentor}</p>
                        </div>
                        <div className="complaint">
                            <button onClick={handleOpenComplaint} className="complaint-btn">
                                <span className="attention">!</span>
                                {t('complaintBtn')}
                            </button>
                        </div>
                    </div>
                    <motion.div
                        className="video-wrapper"
                        key={currentVideoIndex}
                        variants={videoVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.3 }}
                    >
                        {videoUrl ? (
                            <video className="videos videos-height" controls>
                                <source
                                    src={videoUrl}
                                    type="video/mp4"
                                />
                                {t('noSupport')}
                            </video>
                        ) : (
                            <div className="loadingVideo">
                                <img src={WaitVideo} alt="animation" />
                            </div>
                        )}
                    </motion.div>
                    <div className="next-btn-wrapper">
                        <a target="_blank" className="downlaod-btn" onClick={handleViewPdf}>
                            <i className="ri-file-pdf-fill"></i>
                            <span>
                                {t('materialsBtn')}
                            </span>
                        </a>
                        <button className="next-btn" onClick={handleNext}>
                            {currentVideoIndex < videos.length - 1 ? t('nextBtn') : t('goToTest')}
                        </button>
                    </div>
                </div>
                <div className="write-block">
                    <div className="written-material material-block" width="100%">
                        <div>
                            <div className="materials-box">
                                <p className="materials" dangerouslySetInnerHTML={{ __html: currentCourse?.current_lesson?.description }}></p>
                            </div>
                        </div>
                    </div>
                    <div className="course-chat" style={{ width: "100%" }}>
                        <Chat socket="help" courseLanguage={courseLanguage} textOfVideo={currentCourse?.current_lesson?.text_of_video} />
                    </div>
                </div>
                {/*<div className="warning warning-hide">*/}
                {/*    <img src={WarningImg} alt="Warning" className="warning-img"/>*/}
                {/*    <p className="warning-text">*/}
                {/*        At the end of the course, there should be a text reminding that the*/}
                {/*        certificate can be obtained within 1 year and cannot be passed after 1*/}
                {/*        year.*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/* <div className="div next-lessons" style={{paddingTop: "50px"}}>
                    <h2 className="courses-title">Following lessons</h2>
                    <div className="courses-block">
                        {currentCourses.map((course) => (
                            <div className="courses-card" key={course.id}>
                                <div className="lock-lesson">
                                    <img className="lesson-img" src={BookImg} alt="img"/>
                                    <div className="block-lesson">
                                        <img src={Lock} alt="lock"/>
                                    </div>
                                </div>
                                <h3 className="course-name lesson-name">{course.name}</h3>
                                <p className="course-author lesson-author"
                                   style={{marginBottom: "5px !important"}}>{course.author}</p>
                                <div className="card-bottom">
                                    <p className="course-number lessons-number"
                                       style={{marginBottom: "5px !important"}}>
                                        {course.lessonNumber}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
            {complaintModal && (
                <ComplaintModal isOpenComplaint={handleOpenComplaint} complaintTitle={setComplaintTitle} complaintDesc={setComplaintDesc} submitComplaint={submitComplaint} />
            )}
        </Layout>
    );
};

export default StudentDashboardCourseDetail;
