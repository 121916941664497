import React from "react";
import { lessons } from "../Courses/data";
import "../Quiz/Quiz.css";
import Chat from "../../Components/Chat";
import QuizModal from "../../Components/QuizModal/QuizModal";

const Test = () => {
  return (
    <div className="container lesson lesson-quiz">
      <div className="complaint lesson-complaint">
        <button className="complaint-btn">
          <span className="attention">!</span>
          Complain
        </button>
      </div>

      <h2 className="chat-title">Questions about the lesson:</h2>
      <div className="write-block write-quiz-block">
        <div className="chat-wrapper">
          <div className="written-quiz">
            <div>
              <h2 className="course-name quiz-book">Topic: Audit</h2>
              <p className="quiz-info">Teacher: Azizov Aziz</p>
              <p className="quiz-info">Passing Score: 92</p>
              <p className="quiz-info">Number of questions: 20</p>
              <p className="quiz-info">Allocated time: 30 min</p>
            </div>

            <div className="lessons-container tests-wrapper">
              {lessons.map((lesson, index) => (
                <div key={index} className="content-text">
                  <p className="lesson-title">
                    {lesson.num}-lesson: {lesson.title}
                  </p>
                  <p className="lesson-text">{lesson.subtitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="quiz-chat" style={{ width: '100%' }}>
          <Chat />
        </div>
      </div>
      {/* <p className="score-number">Overall score: 95</p> */}
      {/* <QuizModal /> */}
    </div>
  );
};

export default Test;
