import React from 'react';
import './course-progress.css';
import { Link } from 'react-router-dom';

export default function CourseProgress({
    id,
    image,
    title,
    authorFirstName,
    authorLastName,
    progress,
    countOfLessons,
    courseLanguage
}) {
    return (
        <Link to={`/student/courses/${id}`} style={{ textDecoration: 'none' }}>
            <div className="course-container">
                <div className="course-card">
                    <div className="course-content">
                        {/* Book Cover */}
                        <div className="book-cover">
                            <div className="course-img-box">
                                <img className='student-course-image'
                                    src={image}
                                    alt="How Innovation Works book cover"
                                />
                                <div className="course-language">{courseLanguage?.charAt(0).toUpperCase() + courseLanguage?.slice(1)}</div>
                            </div>
                        </div>

                        {/* Course Details */}
                        <div className="course-details">
                            <div className="course-header">
                                <h1 className="title">{title}</h1>
                                <p className="author">by {authorFirstName} {authorLastName}</p>
                            </div>

                            <div className="progress-section">
                                <div className="progress-labels">
                                    <span>{progress}%</span>
                                </div>
                                <div className="progress-bar">
                                    <div className="progress-fill"></div>
                                </div>
                            </div>

                            <div className="course-count">
                                <div className="dot"></div>
                                <span>{countOfLessons} videos</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
