import React, { useEffect, useState } from 'react'
import "./styles.css"
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { API } from "../../../../constants";
import api from '../../../../Components/RefreshToken/RefreshToken';
import Lock from '../../../../images/lock.svg'
import { useTranslation } from 'react-i18next';

const Contents = ({ title = "", lessons }) => {
    const navigate = useNavigate();
    const { enrollmentId } = useParams();
    const [cChapter, setChapter] = React.useState(null);
    const [lessonsList, setLessonsList] = React.useState([]);
    const {t} = useTranslation();

    function handlePlayClick(id) {
    }

    const handleClickChapter = (lesson) => {
        console.log(lesson);

        // navigate(`/student/courses/${enrollmentId}/${lesson?.id}/${lesson?.order}`)
        setChapter((prev) => prev?.id !== lesson.id ? lesson : null);

        api.get(`${API}/courses/chapters/${lesson?.id}/lessons`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }).then(r => {
            setLessonsList(r.data);
        })
    };

    const handleRedirect = (lesson, chapterId) => {
        console.log(lesson)
        navigate(`/student/courses/${enrollmentId}/${chapterId}/${lesson?.order}`)
        // setChapter((prev) => prev?.id !== lesson.id ? lesson : null);

        // axios.get(`${API}/courses/chapters/${lesson?.id}/lessons`, {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}).then(r => {
        //     setLessonsList(r.data);
        // })
    };
    const [lessonsDetail, setLessonsDetail] = useState(null)
    const handleOpenChapter = (chapterId) => {
        api.get(`/student/learning/${enrollmentId}/${chapterId}/lessons`,{ headers: { Authorization: `Bearer ${localStorage.getItem("token")}`}}).then(res => {
            setLessonsDetail(res.data);
            console.log("bu lessonsss",res.data)
        } ).catch((err) => {
            console.log("bu errrrr",err)
        })
    }
    return (
        <div>
            <h3 className='content-title'>{title}</h3>
            <div className='main-content'
                style={{ paddingBottom: '50px', marginBottom: '20px', borderBottom: '1px solid #ddd' }}>
                {lessons?.length === 0 ? (
                    <p style={{ textAlign: "center", minHeight: "100px" }}>{t('noChapter')}</p>
                ) : lessons?.slice().sort((a, b) => a.order - b.order).map((chapter, index) => {
                    return (
                        <div onClick={() => {handleOpenChapter(chapter?.id);handleClickChapter(chapter)}} key={index} className='content-text'>
                            <div>
                                <p className="number-static">{index + 1}</p>
                                <p className='lesson-title'>{chapter.title}</p>
                            </div>
                            {cChapter?.id === chapter?.id && (
                                <div className='chapter-content-l'>
                                    {
                                        lessonsDetail?.length === 0 ? (
                                            <p style={{ textAlign: "center" }}>{t('noLesson')}</p>
                                        ) : lessonsDetail?.map((lesson, indexx) => {
                                            return (
                                                <div key={lesson?.order} onClick={(e) => {chapter?.status === "open" && handleRedirect(lesson, chapter?.id); e.stopPropagation();}} className='lesson-content-chapter'>
                                                    <div className="lesson-content-chapter-m">
                                                        <div className="student-chapter-img">
                                                            <img className="chapter-lesson-photo-l"
                                                                src={`${API}/${lesson?.photo?.replace('http://127.0.0.1:8000/', 'http://192.168.100.112:8001/').replace('0//', '/').replace('1//', '/')}`}
                                                                alt={lesson?.title} />
                                                            {chapter?.status === "lock" && (
                                                                <div className="chapter-lock">
                                                                    <img src={Lock} alt="lock" />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="lesson-content-chapter-m-text">
                                                            <p className="lesson-title-c">{lesson.title}</p>
                                                            {/* <p className="lesson-title-m line-descr">{lesson.description}</p> */}
                                                            <p className="lesson-title-m line-descr" dangerouslySetInnerHTML={{ __html: lesson.description }}></p>
                                                        </div>
                                                    </div>
                                                    {/*<div className="lesson-content-chapter-m-file">*/}
                                                    {/*    <a href="">*/}
                                                    {/*        File*/}
                                                    {/*    </a>*/}
                                                    {/*</div>*/}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Contents;
