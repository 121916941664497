import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios'
import './Courses.css'
import BookImg from '../../images/BookImg.png';

import Lock from '../../images/lock.svg'


import "remixicon/fonts/remixicon.css";
import {useNavigate, useParams} from 'react-router-dom';
import Contents from './components/contents';

import {API} from '../../constants';
import { toast } from 'react-toastify';
import api from '../../Components/RefreshToken/RefreshToken';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const   EnrolledCourseDetails = (props) => {
    const { enrollmentId } = useParams();

    const chatBodyRef = useRef(null)
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [coursesPerPage, setCoursesPerPage] = useState(8)

    const navigate = useNavigate();
    const {t} = useTranslation();

    const goToCourse = () => {
        const token = localStorage.getItem('token');

        if (token) {
            // try {
            //     axios.post(`${API}/student/enroll?course_id=${courseId}`, {}, {
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //             "Content-Type": "application/json"
            //         }
            //     }).then((res) => {
            //         navigate(`/student/courses/${courseId}`);
            //     })
            // } catch (err) {
            // }
        } else {
            toast.error("Login first!")
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePlayClick = (courseId) => {
        navigate(`/courses/${courseId}`);
    };

    useEffect(() => {
            api.get(`${API}/student/learning/${enrollmentId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then((response) => {
                setCourse(response.data);
            }).catch((error) => {
                toast.error(`Error fetching courses: ${error?.message || "Unknown error"}`);
            });

        const handleResize = () => {
            if (window.innerWidth <= 550) {
                setCoursesPerPage(4);
            } else {
                setCoursesPerPage(8);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const currentCourses = courses.slice(
        (currentPage - 1) * coursesPerPage,
        currentPage * coursesPerPage
    );

    const [messages, setMessages] = useState([
        {text: "Hello, how can I help you?", sender: "bot", time: "12:55 am"},
    ]);

    const [inputMessage, setInputMessage] = useState([]);
    const [input, setInput] = useState("");

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages, inputMessage]);

    return (
        <>
            <div className='container'>
                <div className="play" style={{marginBottom: "20px", boxShadow: '0 3px 10px #00000020'}}>
                    <img src={`${API}/${course?.current_lesson?.photo}`} style={{ borderRadius: "10px" }} alt="img" className="play-img"/>
                    <div className="play-block">
                        <div className="play-left">
                            <h2 className="course-name">{course?.current_lesson?.title}</h2>
                            <p className="course-author line-desc" dangerouslySetInnerHTML={{ __html: course?.current_lesson.description }}></p>
                        </div>
                        <div className="play-right">
                            <span className='lesson-num'>{course?.chapters?.length} {t('lesson')}</span>
                            <button onClick={() => navigate(`/student/courses/${enrollmentId}/${course?.current_lesson?.chapter_id}/${course?.current_lesson?.order}`)} className="button play-btn">{t('continueBtn')}</button>
                        </div>
                    </div>
                </div>
                <div>
                    <Contents
                        title={"Course Content"}
                        lessons={course?.course?.chapters}
                        enrollmentId={enrollmentId}
                    />
                </div>
                {/*<div className="warning warning-hide">*/}
                {/*    <img src={WarningImg} alt="Warning" className="warning-img"/>*/}
                {/*    <p className="warning-text">At the end of the course, there should be a text reminding that the*/}
                {/*        certificate can be obtained within 1 year and cannot be passed after 1 year.</p>*/}
                {/*</div>*/}
                <div className="div next-lessons">
                    {/* <h2 className="courses-title">Following lessons</h2> */}
                    <div className="courses-block following-lessons">
                        {currentCourses.map(course => (
                            <div className="courses-card" key={course.id}>
                                <div className='lock-lesson'>
                                    <img className="lesson-img" src={BookImg} alt="img"/>
                                    <div className="block-lesson">
                                        <img src={Lock} alt="lock"/>
                                    </div>
                                </div>
                                <h3 className="course-name lesson-name">{course.name}</h3>
                                <p className="course-author lesson-author">{course.author}</p>
                                <div className="card-bottom">
                                    <p className="course-number lessons-number">{course.lessonNumber}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/*<div className="pagination">*/}
                    {/*    <span className="page-label">Page</span>*/}
                    {/*    <button*/}
                    {/*        className="page-arrow-left page-arrow-left-after"*/}
                    {/*        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}*/}
                    {/*        disabled={currentPage === 1}*/}
                    {/*    ><i className="ri-arrow-left-s-line icon-none"></i></button>*/}
                    {/*    <div className="page-number">*/}
                    {/*        {currentPage}*/}
                    {/*    </div>*/}
                    {/*    <button*/}
                    {/*        className="page-arrow-right page-arrow-right-after"*/}
                    {/*        onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}*/}
                    {/*        disabled={currentPage === totalPages}*/}

                    {/*    ><i className="ri-arrow-right-s-line icon-none"></i></button>*/}
                    {/*    <select*/}
                    {/*        className="page-select"*/}
                    {/*        onChange={(e) => handlePageChange(Number(e.target.value))}*/}
                    {/*    >*/}
                    {/*        <option selected disabled>{totalPages}</option>*/}
                    {/*        {Array.from({ length: totalPages }, (_, i) => (*/}
                    {/*            <option key={i} value={i + 1}>*/}
                    {/*                {i + 1}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>

                
            </div>
        </>
    )
}

export default EnrolledCourseDetails;
