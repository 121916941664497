import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import './Courses.css'
import BookImg from '../../images/BookImg.png'
import WarningImg from '../../images/warning.svg'
import Lock from '../../images/lock.svg'


import "remixicon/fonts/remixicon.css";
import { useNavigate, useParams } from 'react-router-dom';
import Contents from './components/contents';

import { API } from '../../constants';
import { toast } from "react-toastify";
import res from "html2pdf.js";
import api from '../../Components/RefreshToken/RefreshToken';
import { useTranslation } from 'react-i18next';

const Lessons = (props) => {
    const { courseId } = useParams();
    const { t } = useTranslation();

    const [playLesson, setPlayLesson] = useState(false)
    const chatBodyRef = useRef(null)
    const [hiddenChat, setHiddenChat] = useState(false);
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [coursesPerPage, setCoursesPerPage] = useState(8)
    const totalPages = Math.ceil(courses.length / coursesPerPage);

    const navigate = useNavigate();

    const goToCourse = () => {
        const token = localStorage.getItem('token');

        if (token) {
            try {
                api.post(`${API}/student/enroll?course_id=${courseId}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }).then((res) => {
                    // window.location.href = res.data?.redirect_url
                    toast.success("You are enrolled!");
                    navigate(`/student/courses/${res?.data?.id}`);

                }).catch((err) => {
                    if (err.response?.status === 409) {
                        toast.success("You have already enrolled!");
                        navigate(`/student/courses`);
                    }

                    if (err.response?.status === 403) {
                        toast.warning("First Login");
                    }
                })
            } catch (err) {
                toast.error("Error occured")

            }
        } else {
            toast.error("Login first!")
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const hideChatHandle = () => {
        setHiddenChat(true);
    };

    const handlePlayClick = (courseId) => {
        navigate(`/courses/${courseId}`);
    };

    useEffect(() => {
        if (!!props?.courseId || !!courseId) {
            api.get(`${API}/courses/public/detail/${props?.courseId || courseId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then((response) => {
                setCourse(response.data);
            }).catch((error) => {
                toast.error(`Error fetching courses: ${error?.message || "Unknown error"}`);
            });

        }
        const handleResize = () => {
            if (window.innerWidth <= 550) {
                setCoursesPerPage(4);
            } else {
                setCoursesPerPage(8);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const currentCourses = courses.slice(
        (currentPage - 1) * coursesPerPage,
        currentPage * coursesPerPage
    );

    const [messages, setMessages] = useState([
        { text: "Hello, how can I help you?", sender: "bot", time: "12:55 am" },
    ]);

    const [inputMessage, setInputMessage] = useState([]);
    const [input, setInput] = useState("");

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages, inputMessage]);


    return (
        <>
            <div className='container'>
                <div className="play" style={{ marginBottom: "20px" }}>
                    <img src={`${API}/${course?.photo}`} alt="img" className="play-img" />
                    <div className="play-block">
                        <div className="play-left">
                            <h2 className="course-name">{course?.title}</h2>
                            <p className="course-author">{course?.mentor}</p>
                            <p className="course-author line-desc" dangerouslySetInnerHTML={{ __html: course?.description }}></p>
                            {/* <p className='course-desc'>{course?.description}</p> */}
                        </div>
                        <div className="play-right">
                            <span className='lesson-num'>{course?.chapters?.length} {t('lesson')}</span>
                            {!!!props?.courseId ? <button onClick={goToCourse} className="button play-btn">
                                {t("buy")}
                            </button> : <button onClick={goToCourse} className="button play-btn">Learn</button>}
                        </div>
                    </div>
                </div>
                <div>
                    <Contents
                        title={"Course Content"}
                        lessons={course?.chapters?.map((chapter, index) => ({
                            id: chapter?.id,
                            num: index + 1,
                            title: chapter?.title,
                            subtitle: chapter?.description,
                            lessons: chapter?.lessons
                        }))}
                    />
                </div>
                <div className="warning warning-hide">
                    <img src={WarningImg} alt="Warning" className="warning-img" />
                    <p className="warning-text">{t('warningText')}</p>
                </div>
                <div className="div next-lessons">
                    {/* <h2 className="courses-title">Following lessons</h2> */}
                    <div className="courses-block following-lessons">
                        {currentCourses.map(course => (
                            <div className="courses-card" key={course.id}>
                                <div className='lock-lesson'>
                                    <img className="lesson-img" src={BookImg} alt="img" />
                                    <div className="block-lesson">
                                        <img src={Lock} alt="lock" />
                                    </div>
                                </div>
                                <h3 className="course-name lesson-name">{course.name}</h3>
                                <p className="course-author lesson-author">{course.author}</p>
                                <div className="card-bottom">
                                    <p className="course-number lessons-number">{course.lessonNumber}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/*<div className="pagination">*/}
                    {/*    <span className="page-label">Page</span>*/}
                    {/*    <button*/}
                    {/*        className="page-arrow-left page-arrow-left-after"*/}
                    {/*        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}*/}
                    {/*        disabled={currentPage === 1}*/}
                    {/*    ><i className="ri-arrow-left-s-line icon-none"></i></button>*/}
                    {/*    <div className="page-number">*/}
                    {/*        {currentPage}*/}
                    {/*    </div>*/}
                    {/*    <button*/}
                    {/*        className="page-arrow-right page-arrow-right-after"*/}
                    {/*        onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}*/}
                    {/*        disabled={currentPage === totalPages}*/}

                    {/*    ><i className="ri-arrow-right-s-line icon-none"></i></button>*/}
                    {/*    <select*/}
                    {/*        className="page-select"*/}
                    {/*        onChange={(e) => handlePageChange(Number(e.target.value))}*/}
                    {/*    >*/}
                    {/*        <option selected disabled>{totalPages}</option>*/}
                    {/*        {Array.from({ length: totalPages }, (_, i) => (*/}
                    {/*            <option key={i} value={i + 1}>*/}
                    {/*                {i + 1}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}

export default Lessons;
