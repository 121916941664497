import React from 'react';
import './ComplaintModal.css'
import { useTranslation } from 'react-i18next';

const ComplaintModal = ({isOpenComplaint, complaintTitle, complaintDesc, handleChange, submitComplaint}) => {
    const {t} = useTranslation()
    return (
        <form onSubmit={submitComplaint} className='complaint-modal'>
            <div className="complaint-modal-content">
                <div onClick={isOpenComplaint} className="cancel-complaint">
                    <i class="ri-close-line color-white"></i>
                </div>
                <span>Title:</span>
                <input required onChange={(e) => complaintTitle(e.target.value)} className='complaint-input' placeholder='Title...' type="text" />
                <span>Description:</span>
                <textarea required onChange={(e) => complaintDesc(e.target.value)} className='complaint-desc' placeholder='Enter text'></textarea>
                <div className="complaint-btns">
                    <button type='click ' onClick={isOpenComplaint} className="complaint-cancel">{t('cancelBtn')}</button>
                    <button className="complaint-submit">{t('submitBtn')}</button>
                </div>
            </div>
        </form>
    );
}

export default ComplaintModal;
