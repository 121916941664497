import React from "react";
import "./Quiz.css";
import BookImg from "../../images/BookImg.png";
import { useNavigate } from "react-router-dom";

const Quiz = () => {
  const navigate = useNavigate();
  const goToTest = () => {
    navigate("/quiz/test");
  };

  return (
    <div className="container">
      <div className="quiz">
        <div className="lesson-block">
          <div className="lesson-name">
            <h2 className="course-name">The midterm test you need to pass:</h2>
          </div>
          <div className="complaint">
            <button className="complaint-btn quiz-complaint">
              <span className="attention">!</span>
              Complain
            </button>
          </div>
        </div>
        <div className="play quiz-play">
          <img src={BookImg} alt="img" className="play-img" />
          <div className="play-block">
            <div className="play-left">
              <h2 className="course-name">Audit</h2>
              <p className="course-author">Azizov Aziz</p>
            </div>
            <div className="play-right">
              <span className="lesson-num">1-dars</span>
              <button onClick={goToTest} className="button play-btn">
                Play
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
