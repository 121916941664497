import React, { useEffect } from "react";
import "./PhoneNumberInput.css";

const PhoneNumberInput = ({ countryCode, setCountryCode, phoneNumber, setPhoneNumber }) => {
    const countryOptions = [
        { code: "+998", flag: "UZ" }, 
        { code: "+1", flag: "US" }, 
        { code: "+91", flag: "IN" }, 
    ];

    useEffect(() => {
        if (phoneNumber && countryCode && phoneNumber.startsWith(countryCode)) {
            setPhoneNumber(phoneNumber.replace(countryCode, ""));
        }
    }, [countryCode, phoneNumber]);
    

    return (
        <div className="phone-input-container">
            <div className="phone-code">
                <select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    className="phone-code-select input-country"
                >
                    {countryOptions.map((option) => (
                        <option key={option.code} value={option.code}>
                            {option.flag} {option.code}
                        </option>
                    ))}
                </select>
            </div>
            <input
                min={0}
                type="number"
                placeholder="Enter phone number"
                value={phoneNumber}
                required
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="phone-number-input profile-save"
            />
        </div>
    );
};

export default PhoneNumberInput;
