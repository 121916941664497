import React from 'react';
import './Background.css'

const Background = () => {
    return (
        <div className='background'>
        </div>
    );
}

export default Background;
