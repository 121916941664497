import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Cert1 from "../../images/cert1.png";
import Cert2 from "../../images/cert2.png";
import Cert3 from "../../images/cert3.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Slider.css";
import { Autoplay, Navigation } from "swiper/modules";

export default function App() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const certtificateImages = [Cert1, Cert2, Cert3, Cert1];
  const [activeIndex, setActiveIndex] = useState(0);

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage("");
  };

  return (
    <div className="swiper-block">
      <Swiper
        onSwiper={setSwiperRef}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        slidesPerView={2.9}
        centeredSlides={true}
        spaceBetween={0}
        pagination={{
          type: "fraction",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        initialSlide={2}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className="certificate-swiper"
      >
        {certtificateImages.map((image, index) => (
          <SwiperSlide
            key={index}
            className={`certificate-slide certificate-slider ${activeIndex === index ? "active" : ""
              }`}
          >
            <img
              className="certificate-image"
              src={image}
              alt={`certificate ${index + 1}`}
              onClick={() => openModal(image)}
              style={{ cursor: "pointer" }}
            />
            <p className="certificate-slide-desc">
              To receive a certificate at the end of the course, you need to go
              to the office
            </p>
          </SwiperSlide>
        ))}
      </Swiper>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              className="modal-image"
              src={currentImage}
              alt="Full-size certificate"
            />
          </div>
        </div>
      )}
    </div>
  );
}
