import Audit from "../../images/Placeholder.png";

export const data = [
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: true,
    id: 1,
  },

  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 2,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: true,
    id: 3,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: true,
    id: 4,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 5,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: true,
    id: 6,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 7,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 8,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 9,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 10,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 11,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 12,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 13,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 14,
  },
  {
    img: Audit,
    price: "299 000 UZS",
    name: "Audit",
    author: "Azizov Aziz",
    lessons: "40 lessons",
    buy: false,
    id: 15,
  },
];
