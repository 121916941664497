import React from 'react'
import './student-payment.css'

export default function PaymentComponent() {
    return (
        <div className="payment-list">
            <div className="payment-item">
                <div className="payment-grid">
                    <div className="payment-row">
                        <div className="label">Course</div>
                        <div className="value"><a href="#" className="course-link">Audit</a></div>
                    </div>
                    <div className="payment-row">
                        <div className="label">Payment method</div>
                        <div className="value">PayMe</div>
                    </div>
                    <div className="payment-row">
                        <div className="label">Date and time</div>
                        <div className="value">02.02.2025 - 16:00</div>
                    </div>
                    <div className="payment-row">
                        <div className="label">Price</div>
                        <div className="value">1 000 000 UZS</div>
                    </div>
                </div>
            </div>

            <div className="payment-item">
                <div className="payment-grid">
                    <div className="payment-row">
                        <div className="label">Course</div>
                        <div className="value"><a href="#" className="course-link">Bugalteriya</a></div>
                    </div>
                    <div className="payment-row">
                        <div className="label">Payment method</div>
                        <div className="value">PayMe</div>
                    </div>
                    <div className="payment-row">
                        <div className="label">Date and time</div>
                        <div className="value">02.02.2025 - 16:00</div>
                    </div>
                    <div className="payment-row">
                        <div className="label">Price</div>
                        <div className="value">1 000 000 UZS</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
