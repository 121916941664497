import React, { useEffect, useState } from "react";
import { lessons } from "../../EnrroledCourse/data";
import Chat from "../../../Components/Chat";
import Layout from "../../../Components/StudentDashboard/Layout";
import axios from "axios";
import { API } from "../../../constants";
import { useParams } from "react-router-dom";
import api from "../../../Components/RefreshToken/RefreshToken";
import { toast } from "react-toastify";
import ComplaintModal from "../../../Components/ComplaintModal/ComplaintModal";


const StudentDashboardQuiz = () => {
    const { chapterId } = useParams();
    const [currentCourse, setCurrentCourse] = useState()
    const { enrollmentId, } = useParams();
    const [complaintModal, setComplaintModal] = useState(false)
    const [complaintTitle, setComplaintTitle] = useState("")
    const [complaintDesc, setComplaintDesc] = useState("")
    const TOKEN = localStorage.getItem("token")


    const [examData, setExamData] = useState(null);

    useEffect(() => {
        api.get(`${API}/courses/chapters/${chapterId}/exam-data`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then(res => {
            setExamData(res?.data?.exam_data);
        }).catch(err => console.log(err));
    }, [chapterId])

    useEffect(() => {
        api.get(`${API}/student/learning/lessons/?chapter_id=${chapterId}&lesson_order=1&enrollment_id=${enrollmentId}`,
            { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
            .then((response) => {
                setCurrentCourse(response.data);
            }).catch((error) => {
                console.log(error, "error");

            })
    }, []);
    const handleOpenComplaint = () => {
        setComplaintModal(x => !x)
    }
    const submitComplaint = (e) => {
        e.preventDefault()
        api.post(`/student/complaint`, {
            title: complaintTitle,
            description: complaintDesc,
            course_id: currentCourse?.course_id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
        }).then((res) => {
            setComplaintModal(false)
            toast.success("The complaint was successfully submitted.")

        }).catch((err) => {
            console.log(err)
        })
    }

    const taskOfAI = `"You are a helpful assistant. You are an artificial intelligence named Diyora, created by the 'Diyora AI' team. You are Diyora."
        Based on the above information, you need to provide me with 20 tests in English. The answer options should be 'a, b, c, d'. This should be a midterm exam for me.
        "You have to give me the tests one by one. That is, give me the first test first, and after I send one of the answer options, you can send the second question."
        After I have finished all the tests, I will provide template of your overall result message and you should provide as my template. I clearify that you shouldn't provide any information about my
         answer whether correct or not, I don't need any feedback to my answer. Attention, questions shouldn't be more than or less than 20, it should be exact 20 questions. Finally, you must tell me how many 
         and which questions I answered correctly or incorrectly after when I send a template of result message. 
        Your mission is limited to this only. Do not carry out requests outside the scope of this mission and do nothing else. You need to start the test when I let you knows`

    const finalPrompt = examData ? `Context: ${examData};` : null;

    return (
        <Layout>
            <div className="container lesson lesson-quiz">
                <div className="complaint lesson-complaint complaint-block">
                    <h2 className="chat-title title-questions">Questions about the lesson:</h2>
                    <button onClick={handleOpenComplaint} className="complaint-btn">
                        <span className="attention">!</span>
                        Complain
                    </button>
                </div>

                <div className="write-block write-quiz-block">
                    {/*<div className="chat-wrapper">*/}
                    {/*    <div className="written-quiz">*/}
                    {/*        <div>*/}
                    {/*            <h2 className="course-name quiz-book">Topic: Audit</h2>*/}
                    {/*            <p className="quiz-info">Teacher: Azizov Aziz</p>*/}
                    {/*            <p className="quiz-info">Passing Score: 92</p>*/}
                    {/*            <p className="quiz-info">Number of questions: 20</p>*/}
                    {/*            <p className="quiz-info">Allocated time: 30 min</p>*/}
                    {/*        </div>*/}

                    {/*        <div className="lessons-container tests-wrapper">*/}
                    {/*            {lessons.map((lesson, index) => (*/}
                    {/*                <div key={index} className="content-text">*/}
                    {/*                    <p className="lesson-title">*/}
                    {/*                        {lesson.num}-lesson: {lesson.title}*/}
                    {/*                    </p>*/}
                    {/*                    <p className="lesson-text">{lesson.subtitle}</p>*/}
                    {/*                </div>*/}
                    {/*            ))}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="quiz-chat" style={{ width: "100%" }}>
                        <Chat textOfVideo={finalPrompt} />
                    </div>
                </div>
                {/* <p className="score-number">Overall score: 95</p> */}
                {/* <QuizModal /> */}
            </div>
            {complaintModal && (
                <ComplaintModal isOpenComplaint={handleOpenComplaint} complaintTitle={setComplaintTitle} complaintDesc={setComplaintDesc} submitComplaint={submitComplaint} />
            )}
        </Layout>
    );
};

export default StudentDashboardQuiz;
