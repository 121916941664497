import React from 'react'
import Layout from '../../../Components/StudentDashboard/Layout'
import CertificateComponent from '../../../Components/StudentDashboard/Certificate'

export default function StudendDashboardCertificate() {
    return (
        <Layout>
            <CertificateComponent /> 
        </Layout>
    )
}
