import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './News.css';
import Image1 from "../../images/image1.png";
import Image2 from "../../images/image2.png";
import Image3 from "../../images/image3.png";

const News = () => {
    const location = useLocation();
    const initialNews = location.state; // Initial news data from navigation
    const [news, setNews] = useState(initialNews); // State to manage the displayed news

    const certtificateImages = [
        {
            img: Image1,
            id: 1,
            title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            desc: "International Institute of Finance and Accounting IIFA - a leading provider of professional qualifications in accounting, finance, management lorem",
        },
        {
            img: Image3,
            id: 2,
            title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            desc: "IIFA - draws on an extensive network of professional leaders and experts to provide quality input into the practice of finance, accounting and auditing.",
        },
        {
            img: Image2,
            id: 3,
            title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            desc: "We provide timely, effective and cost-effective training solutions that meet both organizational and individual requirements. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
    ];

    return (
        <div className='container'>
            <div className="news-container">
                <div className="news-content">
                    <h1 className="news-name">{news.title}</h1>
                    <img src={news.img} alt="news" className="news-picture" />
                    <p className="news-date">2025.03.14</p>
                    <p className="news-desc">{news.desc}</p>
                </div>
                <div className="news-right">
                    {certtificateImages
                        ?.filter((item) => item.id !== news.id)
                        .slice(0, 2)
                        .map((item, index) => (
                            <div
                                className="news-card"
                                key={index}
                                
                            >
                                <img src={item.img} alt="news" className="news-card-picture" />
                                <h3 onClick={() => setNews(item)}  className="news-card-title">{item.title}</h3>
                                <p className="news-card-desc">{item.desc}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default News;